import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dates from "../Constants/SpeedChartDates";
import DWTUtil from "../Constants/DwtUtil";
import InstructSpeed from "../Constants/InstructSpeed";
import sog from "../Constants/SOG";
import moment from "moment";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";

const SpeedInstructionGraph = (props) => {
  const [DwtUtilGraphPoints, setDwtUtilGraphPoints] = useState("");
  const [InstructSpeedGraphPoints, setInstructSpeedGraphPoints] = useState("");
  const [SogGraphPoints, setSogGraphPoints] = useState("");
  const { fromDate, toDate, vesselId } = props;
  const parseData = (data, key) => {
    return data
      .map((point) => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };
  const options = {
    chart: {
      backgroundColor: null,
      zoomType: "xy",
    },
    title: {
      text: "Instructed Speed / Speed Over Ground / DWT Utilization",
      align: "left",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "",
      },
    },
    legend: {
      // layout: 'vertical',
      // align: 'right',
      // verticalAlign: 'middle',
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemMarginTop: 10,
      itemMarginBottom: 0,
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: "DWT Util. [% of max DWT]",
        },
         min: 0,
         max: 100,
        opposite: false,
      },
      {
        title: {
          text: "Speed [kn]",
        },
        opposite: true,
      },
    ],

    series: [
      {
        name: "DWT Utilization",
        type: "line",
        yAxis: 0,
        data: DwtUtilGraphPoints,
        color: "#4600A0",
        tooltip: {
          valueSuffix: " %",
        },
        marker: {
          enabled: false,
        },
      },
      {
        name: "Instructed Speed",
        type: "scatter",
        yAxis: 1,
        data: InstructSpeedGraphPoints,
        color: "#0000FF",
        marker: {
          symbol: "circle",
          radius: 2.5,
        },
        tooltip: {
          valueSuffix: " kn",
        },
      },
      {
        name: "Speed Over Ground",
        type: "scatter",
        yAxis: 1,
        data: SogGraphPoints,
        color: "#AA00FF",
        marker: {
          symbol: "circle",
          radius: 2.5,
        },
        tooltip: {
          valueSuffix: " kn",
        },
      },
    ],
  };

  const getSpeedInstructionGraph = async () => {
    try {
      const vesselSpeedDataPoints = await apiService.get(
        `${urlService.VesselSpeedPerformance}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );

      const DwtUtilGraphPoints1 = parseData(vesselSpeedDataPoints, "dwtUtil");
      const InstructSpeedGraphPoints1 = parseData(
        vesselSpeedDataPoints,
        "cpSpeed"
      );
      const SogGraphPoints1 = parseData(vesselSpeedDataPoints, "sog");
      setDwtUtilGraphPoints(DwtUtilGraphPoints1);
      setInstructSpeedGraphPoints(InstructSpeedGraphPoints1);
      setSogGraphPoints(SogGraphPoints1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSpeedInstructionGraph();
  }, []);
  return (
  <div style={{marginTop:"10px",  marginLeft:"10px",height:"410px" }} >
  <HighchartsReact highcharts={Highcharts} options={options} />;
  </div>
  );
};

export default SpeedInstructionGraph;
