import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "../../styles/style.css";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "14px !important",
    textOverflow: "ellipsis",
    color: "#000 !important"
  },
  heading: {
    color: theme.palette.common.text,
    fontSize: "1.2em",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    color: "#000 !important"
  },
  container: {
    borderRadius: "6px",
    padding: "8px 10px",
    marginTop: "1px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    height:"260px"
  },
  container1: {
    borderRadius: "6px",
    padding: "8px 10px",
    marginTop: "16px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
   height:"416px"
  },
  container2: {
    borderRadius: "6px",
    padding: "8px 10px",
    marginTop: "16px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
   height:"290px"
  },
  borderShadow: {
    border: "1px solid rgb(215 213 223)",
  },
}));

const formatNumbers = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const roundToDecimalsWithFixed = (number, decimals) => {
  const factor = Math.pow(10, decimals);
  return (Math.round(number * factor) / factor).toFixed(decimals);
};

const roundToDecimals = (number, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};

const adjustPercentages = (percentages, valueKey) => {
  let roundedPercentages = percentages.map((p) => ({
    ...p,
    [valueKey]: Math.round(p[valueKey]),
  }));

  let roundedTotal = roundedPercentages.reduce((sum, p) => sum + p[valueKey], 0);
  let difference = 100 - roundedTotal;

  roundedPercentages = roundedPercentages
    .sort((a, b) => {
      const remainderA = percentages.find((x) => x.data1 === a.data1)[valueKey] % 1;
      const remainderB = percentages.find((x) => x.data1 === b.data1)[valueKey] % 1;
      return remainderB - remainderA;
    })
    .map((p, i) => {
      if (difference > 0 && i < difference) {
        return { ...p, [valueKey]: p[valueKey] + 1 };
      } else if (difference < 0 && i < -difference) {
        return { ...p, [valueKey]: p[valueKey] - 1 };
      }
      return p;
    });

  return roundedPercentages.sort((a, b) => a.order - b.order);
};

export default function MyApp(props) {
  const classes = useStyles();
  const dataset = props.data || [];
  const isPrintPreview = dataset[0]?.isPrintPage || false;

  // Check if dataset has the required fields
  const tableData = dataset[0]?.tabledata || [];
  const pieData = dataset[0]?.pieData || [];
  const pieOptions = dataset[0]?.pieOptions || {};
  const title = dataset[0]?.title || "Default Title";
  const tableHead = dataset[0]?.tablehead || [];

  // Sorting the tabledata by the 'order' field
  const sortedData = [...tableData].sort((a, b) => a.order - b.order);
  const adjustedPercentages = sortedData.length > 0 && adjustPercentages(sortedData, "data3");

  return (
    <>
    {title=="Cargo Delivered in Period [mt]:" ?
    <Grid 
      container
      className={`${classes.container1} ${!isPrintPreview ? classes.borderShadow : ""}`}
    >
     
      <Grid item xs={7} style={{ padding: "4px" }}>
        <Typography
          variant="h6"
          component="h2"
          className={classes.heading}
          textAlign="left"
          style={{ paddingBottom: "10px" }}
        >
          {title}
        </Typography>
        {tableHead.map((val, index) => (
      
          <Grid
            container
            key={index}
            style={{ borderBottom: "1px solid rgb(13, 1, 65)" }}
          >
            <Grid item xs={6} alignContent="left" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "left", width: "100%" }}
              >
                {val.data1 || "N/A"}
                
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="flex-end" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "right", width: "100%" }}
              >
                {val.data2 !== undefined
                  ? props.common === 2
                    ? roundToDecimalsWithFixed(val.data2, 1).toLocaleString()
                    : roundToDecimals(val.data2, 0).toLocaleString()
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="flex-end" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "right", width: "100%" }}
              >
                {val.data3 || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {adjustedPercentages &&
          adjustedPercentages.map((val, index) => (
            <Grid container key={index} style={{ padding: "6px 0px 0px 0px" }}>
              <Grid item xs={6} alignContent="left" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "left", width: "100%" }}
                >
                    
                  {val.data1 || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={3} justifyContent="flex-end" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "right", width: "100%" }}
                >
                  {val.data2 !== undefined
                    ? props.common === 2
                      ? roundToDecimalsWithFixed(val.data2, 1)
                      : roundToDecimals(val.data2, 0).toLocaleString()
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid  item xs={3} justifyContent="flex-end" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "right", width: "100%" }}
                >
                  {val.data3 !== undefined ? `${val.data3}%` : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid item xs={5} style={{ paddingTop: "30px" }}>
        <Grid>
          <Chart
            chartType="PieChart"
            width="100%"
            height="200px"
            data={pieData.slice(0, 5)}
            colors={"#FB7A21"}
            text={false}
            options={pieOptions}
          />
        </Grid>
      </Grid>
    </Grid>
    :
    title=="Distance Covered in Period [nautical miles]:" ?
      <Grid 
        container
        className={`${classes.container2} ${!isPrintPreview ? classes.borderShadow : ""}`}
      >
       
        <Grid item xs={7} style={{ padding: "4px" }}>
          <Typography
            variant="h6"
            component="h2"
            className={classes.heading}
            textAlign="left"
            style={{ paddingBottom: "10px" }}
          >
            {title}
          </Typography>
          {tableHead.map((val, index) => (
        
            <Grid
              container
              key={index}
              style={{ borderBottom: "1px solid rgb(13, 1, 65)" }}
            >
              <Grid item xs={6} alignContent="left" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "left", width: "100%" }}
                >
                  {val.data1 || "N/A"}
                  
                </Typography>
              </Grid>
              <Grid item xs={3} justifyContent="flex-end" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "right", width: "100%" }}
                >
                  {val.data2 !== undefined
                    ? props.common === 2
                      ? roundToDecimalsWithFixed(val.data2, 1).toLocaleString()
                      : roundToDecimals(val.data2, 0).toLocaleString()
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={3} justifyContent="flex-end" display="flex">
                <Typography
                  variant="h6"
                  className={classes.text}
                  style={{ textAlign: "right", width: "100%" }}
                >
                  {val.data3 || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          ))}
          {adjustedPercentages &&
            adjustedPercentages.map((val, index) => (
              <Grid container key={index} style={{ padding: "6px 0px 0px 0px" }}>
                <Grid item xs={6} alignContent="left" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "left", width: "100%" }}
                  >
                      
                    {val.data1 || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={3} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "right", width: "100%" }}
                  >
                    {val.data2 !== undefined
                      ? props.common === 2
                        ? roundToDecimalsWithFixed(val.data2, 1)
                        : roundToDecimals(val.data2, 0).toLocaleString()
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid  item xs={3} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "right", width: "100%" }}
                  >
                    {val.data3 !== undefined ? `${val.data3}%` : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={5} style={{ paddingTop: "30px" }}>
          <Grid>
            <Chart
              chartType="PieChart"
              width="100%"
              height="200px"
              data={pieData.slice(0, 5)}
              colors={"#FB7A21"}
              text={false}
              options={pieOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      :
    <Grid
    container
    className={`${classes.container} ${!isPrintPreview ? classes.borderShadow : ""}`}
  >
    <Grid item xs={7} style={{ padding: "4px" }}>
      <Typography
        variant="h6"
        component="h2"
        className={classes.heading}
        textAlign="left"
        style={{ paddingBottom: "10px" }}
      >
        {title}
      </Typography>
      {tableHead.map((val, index) => (
    
        <Grid
          container
          key={index}
          style={{ borderBottom: "1px solid rgb(13, 1, 65)" }}
        >
          <Grid item xs={6} alignContent="left" display="flex">
            <Typography
              variant="h6"
              className={classes.text}
              style={{ textAlign: "left", width: "100%" }}
            >
              {val.data1 || "N/A"}
              
            </Typography>
          </Grid>
          <Grid item xs={3} justifyContent="flex-end" display="flex">
            <Typography
              variant="h6"
              className={classes.text}
              style={{ textAlign: "right", width: "100%" }}
            >
              {val.data2 !== undefined
                ? props.common === 2
                  ? roundToDecimalsWithFixed(val.data2, 1).toLocaleString()
                  : roundToDecimals(val.data2, 0).toLocaleString()
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3} justifyContent="flex-end" display="flex">
            <Typography
              variant="h6"
              className={classes.text}
              style={{ textAlign: "right", width: "100%" }}
            >
              {val.data3 || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {adjustedPercentages &&
        adjustedPercentages.map((val, index) => (
          <Grid container key={index} style={{ padding: "6px 0px 0px 0px" }}>
            <Grid item xs={6} alignContent="left" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "left", width: "100%" }}
              >
                  
                {val.data1 || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="flex-end" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "right", width: "100%" }}
              >
                {val.data2 !== undefined
                  ? props.common === 2
                    ? roundToDecimalsWithFixed(val.data2, 1)
                    : roundToDecimals(val.data2, 0).toLocaleString()
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid  item xs={3} justifyContent="flex-end" display="flex">
              <Typography
                variant="h6"
                className={classes.text}
                style={{ textAlign: "right", width: "100%" }}
              >
                {val.data3 !== undefined ? `${val.data3}%` : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
    <Grid item xs={5} style={{ paddingTop: "10px" }}>
      <Grid>
        <Chart
          chartType="PieChart"
          width="100%"
          height="200px"
          data={pieData.slice(0, 5)}
          colors={"#FB7A21"}
          text={false}
          options={pieOptions}
        />
      </Grid>
    </Grid>
  </Grid>}
    </>
  );
}