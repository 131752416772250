let Co2EmissionDates = [
  "31/12/23",
  "01/01/24",
  "02/01/24",
  "03/01/24",
  "04/01/24",
  "05/01/24",
  "06/01/24",
  "07/01/24",
  "08/01/24",
  "09/01/24",
  "10/01/24",
  "11/01/24",
  "12/01/24",
  "13/01/24",
  "14/01/24",
  "15/01/24",
  "16/01/24",
  "17/01/24",
  "18/01/24",
  "19/01/24",
  "20/01/24",
  "21/01/24",
  "22/01/24",
  "23/01/24",
  "24/01/24",
  "25/01/24",
  "26/01/24",
  "27/01/24",
  "28/01/24",
  "29/01/24",
  "30/01/24",
  "31/01/24",
  "01/02/24",
  "02/02/24",
  "03/02/24",
  "04/02/24",
  "05/02/24",
  "06/02/24",
  "07/02/24",
  "08/02/24",
  "09/02/24",
  "10/02/24",
  "11/02/24",
  "12/02/24",
  "13/02/24",
  "14/02/24",
  "15/02/24",
  "16/02/24",
  "17/02/24",
  "18/02/24",
  "19/02/24",
  "20/02/24",
  "21/02/24",
  "22/02/24",
  "23/02/24",
  "24/02/24",
  "25/02/24",
  "26/02/24",
  "27/02/24",
  "28/02/24",
  "29/02/24",
  "01/03/24",
  "02/03/24",
  "03/03/24",
  "04/03/24",
  "05/03/24",
  "06/03/24",
  "07/03/24",
  "08/03/24",
  "09/03/24",
  "10/03/24",
  "11/03/24",
  "12/03/24",
  "13/03/24",
  "14/03/24",
  "15/03/24",
  "16/03/24",
  "17/03/24",
  "18/03/24",
  "19/03/24",
  "20/03/24",
  "21/03/24",
  "22/03/24",
  "23/03/24",
  "24/03/24",
  "25/03/24",
  "26/03/24",
  "27/03/24",
  "28/03/24",
  "29/03/24",
  "30/03/24",
  "31/03/24",
  "01/04/24"
].map((date) => {
  const split = date.split('/');
  return new Date(split[1] + '/' + split[0] + '/' + split[2])
});
export default Co2EmissionDates