import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";
import co2Line from "../Constants/Co2Line";
import { Grid, Tooltip, Typography } from "material-ui";
import { fontString } from "chart.js/helpers";
import { min } from "date-fns";
import { zIndex } from "material-ui/styles";

function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([]);
  const [datamax, setMax] = useState(0);
  const [datamin, setMin] = useState(0);
  const [range, setRange] = useState(0);

  const co2EmissionChartConfig = {
    chart: {
      backgroundColor: "none", // No background color for the chart
      type: "area",
    },

    title: {
      text: `IMO – Carbon Intensity Indicator (CII) `,
      align: 'left',
      useHTML: true,
      style: {
        height: '35px' // Correct way to set height
      }
    },
    subtitle: {
      text: `
        <div style="paddingBottom:10px"><div style="
      text-indent: 120px;
      color: black;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
    ">
      AER = ${props.additionalData.aerT2W} gCO2/DWTxnm
    </div>
        &nbsp;
        <div style="
          color: black;
          font-weight: bold;
          font-size: 14px;
          display: inline-block;
          text-indent: 55px;
        ">
          CII Rating : <span style="
        background: ${props.additionalData.cii == "B" ? "#98cd51"
          : props.additionalData.cii == "A" ? "Green"
            : props.additionalData.cii == "C" ? "#ffff0a"
              : props.additionalData.cii == "D" ? "#ffbe00"
                : props.additionalData.cii == "E" ? "red" : "#f8ba05"
        };
        color: ${props.additionalData.cii == "B"
          ? "#000"
          : props.additionalData.cii == "A"
            ? "#fff"
            : props.additionalData.cii == "C"
              ? "#000"
              : props.additionalData.cii == "E" ? "#fff" : "#000"
        };
        padding: 7px 10px;
        
      ">${props.additionalData.cii}</span>
          
        </div></div>
      `,
      align: "left", // Aligns the subtitle to the left
      useHTML: true, // Enables the use of HTML in the subtitle
    },
    legend: false,
    credits: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 1, // Set grid line width for yAxis
      gridLineColor: "white", // Set grid line color for yAxis
      max: datamax,
      min: datamin,
      startOnTick: false, // Prevents rounding of min value
      endOnTick: false,
      tickInterval: 0.5,

      title: {
        useHTML: true,
        text: "AER [gCO2/DWTxnm]",
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(1);
        },
      },
      plotBands: [
        {
          from: range.yxLow,
          to: range.ciiab,
          color: "Green",
          zIndex: 2,
        },
        {
          from: range.ciiab,
          to: range.ciibc,
          color: "#98cd51",
          zIndex: 2,
        },
        {
          from: range.ciibc,
          to: range.ciicd,
          color: "#ffff0a",
          zIndex: 2,
        },
        {
          from: range.ciicd,
          to: range.ciide,
          color: "#ffbe00",
          zIndex: 2,
        },
        {
          from: range.ciide,
          to: range.yxUp,
          color: "#fe0200",
          zIndex: 2,
        },
      ],
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "",
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const date = moment(this.x).format('YYYY-MM-DD'); // Format the date
        return `
          <b>${this.series.name}</b><br/>
          Date: ${date}<br/>
          Value: ${this.y}
        `;
      },
    },
    plotOptions: {
      area: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
      },
    },
    series: dataSeries,
  };

  const parseData = (data, key) => {
    return data
      .map((point) => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };

  const getCo2EmissionChart = async () => {
    try {
      const Co2EmissionDataPoints = await apiService.get(
        `${urlService.co2EmissionChart}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      const dataSeriesArray = [
        {
          name: "ciIref",
          color: "#000",
          type: "line",
          dashStyle: "LongDash",

          data: parseData(Co2EmissionDataPoints, "ciIref"),
        },
        {
          name: "AER T2W",
          color: "#4600A0",
          type: "line",
          data: parseData(Co2EmissionDataPoints, "ciicO2"),
        },
      ];

      const Co2EmissionDataPointIndex =
        Co2EmissionDataPoints &&
        Co2EmissionDataPoints.length > 0 &&
        Co2EmissionDataPoints[0];
      setDataSeries(dataSeriesArray);
      setRange(Co2EmissionDataPointIndex);

      setMax(Co2EmissionDataPointIndex.yxUp);
      setMin(Co2EmissionDataPointIndex.yxLow);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCo2EmissionChart();
  }, []);
  return (
    <div className="charts-container center" style={{ paddingBottom: "10px", marginTop: "10px", marginLeft: "10px" }}>

      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={co2EmissionChartConfig}
        />
      </>
      <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "green",
              width: "12px",
              height: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          ></div>
          CII A
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#98cd51",
              width: "12px",
              height: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          ></div>
          CII B
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#ffff0a",
              width: "12px",
              height: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          ></div>
          CII C
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#ffbe00",
              width: "12px",
              height: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          ></div>
          CII D
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#fe0200",
              width: "12px",
              height: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          ></div>
          CII E
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#000",
              width: "20px",
              height: "2px",
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "6px",
            }}
          ></div>
          CII Ref Line
        </div>
        <div style={{ paddingRight: "20px", fontSize: "12px" }}>
          <div
            style={{
              backgroundColor: "#4600A0",
              width: "20px",
              height: "2px",
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "6px",
            }}
          ></div>
          AER T2W
        </div>
      </div>

    </div>
  );
}

export default Co2EmissionChart;
