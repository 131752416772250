import React from 'react';
import { Typography } from "@mui/material";
import { Grid } from 'rsuite';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "14px !important",
    textOverflow: "ellipsis",
    textAlign: 'left !important',
    paddingBottom: '10px',
    paddingLeft: '0px !important',
    marginLeft: '0px !important',
  },
  header: {
    color: theme.palette.common.text,
    fontSize: "1.2em",
    textOverflow: "ellipsis",
    fontWeight: "bold"
  }
}))

const AbbreviationBox = () => {
  const classes = useStyles();

  return (
    <Grid container className="full-container" style={{ width: '100%' }}>
      <Typography variant="h6" className={classes.header} align="left" paddingBottom={'10px'}>
        <strong>Abbreviations:</strong>
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>CO<span style={{ fontSize: '11px' }}>2</span> Emission:</strong> CO<span style={{ fontSize: '11px' }}>2</span> emission from fuel burnt
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>CO<span style={{ fontSize: '11px' }}>2e</span> Emission:</strong> CO<span style={{ fontSize: '11px' }}>2e</span> emission (CO<span style={{ fontSize: '11px' }}>2</span> including Global Warming Potential of N<span style={{ fontSize: '11px' }}>2</span>O and CH<span style={{ fontSize: '11px' }}>4</span> emission)
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>Tank-2-Wake:</strong> Emission from fuel burnt onboard vessel
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>Well-2-Wake:</strong> Emission from fuel burnt onboard vessel including emission from production of fuel
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>AER:</strong> Annual Efficiency Ratio based on CO<span style={{ fontSize: '11px' }}>2</span> emission [gCO<span style={{ fontSize: '11px' }}>2</span>/DWTxnm]
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>AER<span style={{ fontSize: '11px' }}>e</span>:</strong> Annual Efficiency Ratio based on CO<span style={{ fontSize: '11px' }}>2e</span> emission [gCO<span style={{ fontSize: '11px' }}>2e</span>/DWTxnm]
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>EEOI<span style={{ fontSize: '11px' }}>e</span>:</strong> Energy Efficiency Operational Indicator based on CO<span style={{ fontSize: '11px' }}>2e</span> emission [gCO<span style={{ fontSize: '11px' }}>2</span>e/mtxnm]
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>CII:</strong> Carbon Intensity Indicator
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>PP - SF:</strong> Poseidon Principles for Ship Finance (minimum requirement)
      </Typography>
      <Typography variant="h6" className={classes.text}>
        <strong>SCC:</strong> Sea Cargo Charter (minimum requirement)
      </Typography>
    </Grid>
  );
};
export default AbbreviationBox;
