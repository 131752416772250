import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";


function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([])
  const [maxPoint, setMaxPoint] = useState(0)
  const [minPoint, setMinPoint] = useState(0)

  const PPGraphConfig = {
    chart: {
      backgroundColor: null,
      height: '440px',
      type: 'line'
    },
    title: {
      text: `Poseidon Principles for Ship Finance (PP-SF) `,
      align: 'left',
      useHTML: true ,
      style: {
        height: '35px' // Correct way to set height
      }
    },
    subtitle: {
      text: `
        <div style="paddingBottom:10px"><div style="
      text-indent: 120px;
      color: black;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
    ">
      AERe = ${props.additionalData.aerW2W} gCO2e/mtxnm
    </div>
        &nbsp;
        <div style="
          color: black;
          font-weight: bold;
          font-size: 14px;
          display: inline-block;
          text-indent: 55px;
        ">
          PP-SF Alignment = <span style=" color: white;
        background: ${props.additionalData.aerW2W > 0 ? '#ff3300' : '#2cc932'};
        padding: 7px 10px;
        border-radius:10px;
      ">${props.additionalData.ppa}%</span>
          
        </div></div>
      `,
      align: 'left', // Aligns the subtitle to the left
      useHTML: true  // Enables the use of HTML in the subtitle
    }
    ,
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1, // Set grid line width for xAxis
      gridLineColor: '#cccccc', // Set grid line color for xAxis
      title: {
        text: ''
      }
    },
    yAxis: {

      gridLineWidth: 1, // Set grid line width for yAxis
      gridLineColor: '#cccccc', // Set grid line color for yAxis
      tickInterval: 0.5,
      title: {
        text: 'AERe [gCO2e/DWTxnm]'
      },
      labels: {
        formatter: function() {
          return this.value.toFixed(1);   
        },
      },

      // min: 0,
      // max: maxPoint

    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      // layout: 'vertical',
      // align: 'right',
      // verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 0
    },
    credits: {
      enabled: false
    },
    series: dataSeries,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800
        },
      }]
    }
  };

  const parseData = (data, key) => {
    return data
      .map(point => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };
  const getVesselPPGraph = async () => {
    try {
      const vesselPPDataPoints = await apiService.get(`${urlService.VesselPPGraph}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`)
      const YxUp = parseData(vesselPPDataPoints, 'yxUp');
      const dataSeriesArray = [
      //   {
      //   name: 'YxUp',
      //   data: YxUp,
      //   color: 'purple',
      //   marker: {
      //     enabled: false
      //   },
      //   showInLegend: false
      // },
       {
        name: 'PP-SF Minimum',
        data: parseData(vesselPPDataPoints, 'ppMin'),
        color: '#AA00FF',
        marker: {
          enabled: false
        }
      }, {
        name: 'PP-SF Striving',
        data: parseData(vesselPPDataPoints, 'ppStriving'),
        color: '#0000FF',
        marker: {
          enabled: false
        }
      },
      {
        name: 'AERe W2W',
        data: parseData(vesselPPDataPoints, 'ciicO2'),
        color: '#4600A0',
        type: 'line',
        marker: {
          enabled: false
        }
      }, 
      // {
      //   name: 'YxLow',
      //   data: parseData(vesselPPDataPoints, 'yxLow'),
      //   color: 'purple',
      //   marker: {
      //     enabled: false
      //   },
      //   showInLegend: false
      // }
    ];

      setDataSeries(dataSeriesArray);
      // setMaxPoint(YxUp[0][1])
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getVesselPPGraph()
  }, [])
  return (
    <div style={{height:"455px",marginTop:"10px",  marginLeft:"10px" }} className="charts-container center">
      <>
        <HighchartsReact highcharts={Highcharts} options={PPGraphConfig} />
      </>
    </div>
  );
}

export default Co2EmissionChart;