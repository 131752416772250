import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import Logo from "../assets/logooceanpass.png";
import Menu from "@mui/material/Menu";
// import Avatar from "@mui/material/Avatar";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import "../styles/style.css";
import { makeStyles } from "@material-ui/core/styles";
// import InvertColors from "@mui/icons-material/InvertColors";
// import { ThemeContext } from "../contexts";
import { useAuth } from "../utils/AuthProvider";

const settings = ["Profile", "Overview", "Logout"];

const useStyles = makeStyles((theme) => ({
  deskTopAddBtn: {
    paddingRight: 12,
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 10000
  },
  headersubtext: {
    fontSize: "16px !important",
    padding: "0px 14px",
    color: theme.palette.common.black,
  },
  themeIcon: {
    color: theme.palette.common.icon,
  }
}))

export default function Header(props) {
  const { userinfo, logout } = useAuth();
  // const theme = React.useContext(ThemeContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const redirectoverview = () => {
    navigate("/fleetoverview");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} >
        <Toolbar style={{ zIndex: "10000" }}>
          {/* <IconButton
            color="#000"
            aria-label="open drawer"
            onClick={() => props.toogleDrawer()}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Grid className="Test-class"
            container
            style={{ flexGrow: 1, 
              // textAlign: "left",
               padding: 0, display: "flex" }}
          >
            <img
              alt="logo"
              onClick={() => redirectoverview()}
              src={Logo}
              width="100px"
              style={{ paddingRight: "10px" }}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid alignItems="center" display="flex">
              <Typography className={classes.headersubtext} align="left">
                {userinfo.companyName}
              </Typography>
            </Grid>
            <Grid alignItems="center" display="flex" style={{ marginLeft: "auto" }}>
              <Typography className={classes.headersubtext} align="right">
                Welcome : {userinfo.accountName}
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Link component="button" variant="body1" className="linktext" style={{ marginLeft: "10px" }} align="right"
                onClick={() => {
                  logout()
                  navigate('/')
                }}
              >
                Logout
              </Link>
            </Grid>
          </Grid>

          {/* <Grid className={classes.deskTopAddBtn}>
              <Tooltip
                title="Dark/Lite mode"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <IconButton
                  onClick={() => {
                    theme.setTheme({
                      ...theme,
                      isDarkTheme: !theme.isDarkTheme,
                    });
                  }}
                >
                  <InvertColors className={classes.themeIcon}/>
                </IconButton>
              </Tooltip>
          </Grid> */}


          <Grid>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>

    </Box>
  );
}