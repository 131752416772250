import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/User/Login";
import ClientConfig from "../pages/ClientConfig"
import Fleetoverview from "../pages/FleetOverview";
import ReportDetails from "../pages/ReportDetails";
import PrintPreview from "../pages/PrintPreview"
import ClientView from "../pages/ClientView"
import { AuthProvider } from "../utils/AuthProvider";
import PrivateRoutes from "../utils/PrivateRoutes";

export default function Routerreact() {
  return (
    <BrowserRouter>
      <AuthProvider>
        {" "}
        <Routes>
          {/* 👇️ redirect to /dashboard when user goes to / */}
          <Route path="/sign-up" element={<Login signup={true} />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/fleetoverview" element={<Fleetoverview />} />
            <Route path="/detailpage/:vesselId" element={<ReportDetails />} />
            <Route path="/print/:vesselId" element={<PrintPreview />} />
            <Route path="/new-client" element={<ClientConfig />} />
            <Route path="/info/:providerId" element={<ClientView />} />
          </Route>
          {/* 👇️ only match this when no other routes match */}
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter >
  );
}
