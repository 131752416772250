import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "../../styles/style.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "14px !important",
    textOverflow: "ellipsis",
    textAlign: "left",
    color:"#000 !important"
  },
  textwrap: {
    width: "120px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px !important",
    textAlign: "left",
  },
  header: {
    color: theme.palette.common.text,
    fontSize: "1.2em",
    textOverflow: "ellipsis",
    fontWeight: "bold",
     color:"#000 !important"
  },
  container: {
    "border-radius": "6px",
    padding: "8px 10px",
    //"margin-top": "16px",
    "box- shadow":
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  borderShadow: {
    border: "1px solid rgb(215 213 223)",
  },
}));

const formatNumbers = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const roundToDecimals = (number, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};

export default function MyApp(props) {
  const classes = useStyles();
  const dataset = props.data;
  const dataset1 = props.datatwo;
  const isPrintPreview = dataset[0]?.isPrintPage ? true : false;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          className={`${classes.container} ${
            !isPrintPreview ? classes.borderShadow : ""
          }`}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.header}
              textAlign="left"
              style={{
                borderBottom: "1px solid rgb(13, 1, 65)",
                paddingBottom: "32px",
                color:"#000 !important"
              }}
            >
              {dataset1[0].title }
            </Typography>

            {dataset1[0].tabledata.map((val, index) => (
              <Grid
                container
                key={index}
                style={{ padding: "6px 0px 0px 0px" }}
              >
                <Grid item xs={7} alignContent="left">
                  <Typography
                    align="left"
                    style={{color:"#000 !important"}}
                    noWrap="nowrap"
                    variant="h6"
                    className={classes.text}
                  >
                    {val.data1}
                  </Typography>
                </Grid>
                <Grid item xs={2} justifyContent="center" display="flex">
                  <Typography variant="h6" className={classes.text}>
                    {val.data2 === "CO2" ? (
                      <>
                        CO<span style={{ fontSize: "11px" }}>2</span>
                      </>
                    ) : val.data2 === "CO2e" ? (
                      <>
                        CO<span style={{ fontSize: "11px" }}>2e</span>
                      </>
                    ) : (
                      val.data2
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2.6} justifyContent="flex-end" display="flex">
                  <Typography variant="h6" className={classes.text}>
                  {val.data3 &&
                  roundToDecimals(val.data3, 0).toLocaleString()}
                  {/* {val.data3 && val.data3.toLocaleString()} */}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          className={`${classes.container} ${
            !isPrintPreview ? classes.borderShadow : ""
          }`}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.header}
              textAlign="left"
              style={{ paddingBottom: "10px" }}
            >
              {dataset[0].title}
            </Typography>
            {dataset[0].tablehead.map((val, index) => (
              <Grid
                container
                key={index}
                style={{ borderBottom: "1px solid rgb(13, 1, 65)" }}
              >
                <Grid item xs={4} alignContent="left" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    {val.data1}
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{
                      textAlign: "left",
                      width: "100%",
                      textTransform: "capitalize",
                    }}
                  >
                  
                  </Typography>
                </Grid>
                <Grid item xs={7} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{
                      textAlign: "right",
                      width: "100%",
                      textTransform: "capitalize",
                      paddingRight:"10px"
                    }}
                  >
                    {props.dynamicName} 
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {dataset[0].tabledata.map((val, index) => (
              <Grid
                container
                key={index}
                style={{ padding: "6px 0px 0px 0px" }}
              >
                <Grid item xs={4} alignContent="left" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    {val.data1}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    {val.data2}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} justifyContent="flex-end" display="flex">
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ textAlign: "right", width: "100%" }}
                  >
                    {val.data3 && val.data3.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
