import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";
import Co2EmissionDates from "../Constants/Co2Emission/C02EmissionDate";
import lineB from "../Constants/Co2Emission/lineB";
import lineC from "../Constants/Co2Emission/lineC";
import lineD from "../Constants/Co2Emission/lineD";
import lineE from "../Constants/Co2Emission/lineE";
import CIIREF from "../Constants/Co2Emission/CIIREF";
import CIIREF2019 from "../Constants/Co2Emission/CIIREF2019";
import CIIREF2019Dates from "../Constants/Co2Emission/CIIREF2019Dates";


const foundMin = Math.min(...CIIREF2019)
function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([])
  const [min, setMin] = useState([])

  const co2EmissionChartConfig = {
    chart: {
      backgroundColor: null,
      type: 'line',
      // marginTop: 50
    },
    title: {
      useHTML: true,
      text: 'Carbon Intensity Indicator (CII) and Annual Efficiency Ratio (AER)',
      align: 'left'
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 0,
      itemMarginBottom: 0
    },
    credits: {
      enabled: false
    },
    yAxis: {
      // min: foundMin,
      // labels: { enabled: false },
      // min: foundMin,
      // startOnTick: false,
      // endOnTick: false,
      title: {
        useHTML: true,
        text: 'AER [gCO2/DWTxnm]'
      }
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Period'
      }
    },
    tooltip: false,
    plotOptions: {
      area: {
        // stacking: 'percent',
        stacking: 'normal',
        marker: {
          enabled: false
        }
      }
    },
    series: dataSeries
  };

  const parseData = (data, key) => {
    return data
      .map(point => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };

  const parseStaticData = (dates, values) => {
    // return dates.map((date, index) => console.log(date, Date.parse(date)))
    return dates.map((date, index) => [Date.parse(new Date(date)), values[index]])
    // .sort((a, b) => a[0] - b[0]);
  };

  const getCo2EmissionChart = async () => {
    try {
      // const Co2EmissionDataPoints = await apiService.get(`${urlService.co2EmissionChart}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`)
      const dataSeriesArray = [
        {
          name: 'CII E Rating',
          color: 'lightgreen',
          data: parseStaticData(Co2EmissionDates, lineE)
        },
        {
          name: 'CII D Rating',
          color: 'purple',
          data: parseStaticData(Co2EmissionDates, lineD)
        },
        {
          name: 'CII  REF',
          color: 'orange',
          data: parseStaticData(Co2EmissionDates, CIIREF)
        },
        {
          name: 'CII C Rating',
          color: 'skyblue',
          data: parseStaticData(Co2EmissionDates, lineC)
        },
        {
          name: 'CII B Rating',
          color: 'darkgreen',
          data: parseStaticData(Co2EmissionDates, lineB)
        },
        {
          name: 'AER CII CO2 T2W',
          color: 'blue',
          data: parseStaticData(Co2EmissionDates, CIIREF2019)
        },
      ];
      setDataSeries(dataSeriesArray);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCo2EmissionChart()
  }, [])
  return (
    <div className="charts-container center">
      <>
        <HighchartsReact highcharts={Highcharts} options={co2EmissionChartConfig} />
      </>
    </div>
  );
}

export default Co2EmissionChart;