import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';

const validationSchema = Yup.object({
  IMONo: Yup.string().required('Required'),
  Name: Yup.string().required('Required'),
  VesselType: Yup.string().required('Required'),
  PortofRegistry: Yup.string().required('Required'),
  DeliveryDate: Yup.date().required('Required'),
  DeliveryYard: Yup.string().required('Required'),
  MaxDraft: Yup.number().required('Required'),
  MaxDeadWg: Yup.number().required('Required'),
  EEDI: Yup.number().required('Required'),
  EEDISpeed: Yup.number().required('Required'),
  EEXI: Yup.number().required('Required'),
  EEXISpeed: Yup.number().required('Required'),
  Owner: Yup.string().required('Required'),
  Charterer: Yup.number().required('Required'),
  ShipManager: Yup.string().required('Required'),
});

const VesselForm = ({ open, handleClose, onSubmit, fleetInfo }) => {
  const defaultValues = {
    IMONo: '',
    Name: '',
    VesselType: '',
    PortofRegistry: '',
    DeliveryDate: '',
    DeliveryYard: '',
    MaxDraft: '',
    MaxDeadWg: '',
    EEDI: '',
    EEDISpeed: '',
    EEXI: '',
    EEXISpeed: '',
    Owner: '',
    Charterer: '',
    ShipManager: '',
  };

  const formikInitialValues = fleetInfo || defaultValues;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{fleetInfo ? 'Edit Vessel' : 'Add Vessel'}</DialogTitle>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleClose();
          onSubmit(values)
        }}
      >
        {({ isSubmitting, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="IMONo"
                    label="IMO No *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.IMONo && Boolean(errors.IMONo)}
                    helperText={touched.IMONo && errors.IMONo ? errors.IMONo : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="Name"
                    label="Name *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Name && Boolean(errors.Name)}
                    helperText={touched.Name && errors.Name ? errors.Name : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="VesselType"
                    label="Vessel Type *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.VesselType && Boolean(errors.VesselType)}
                    helperText={touched.VesselType && errors.VesselType ? errors.VesselType : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="PortofRegistry"
                    label="Port of Registry *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.PortofRegistry && Boolean(errors.PortofRegistry)}
                    helperText={touched.PortofRegistry && errors.PortofRegistry ? errors.PortofRegistry : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="DeliveryDate"
                    label="Delivery Date*"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.DeliveryDate && Boolean(errors.DeliveryDate)}
                    helperText={touched.DeliveryDate && errors.DeliveryDate ? errors.DeliveryDate : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="DeliveryYard"
                    label="Delivery Yard *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.DeliveryYard && Boolean(errors.DeliveryYard)}
                    helperText={touched.DeliveryYard && errors.DeliveryYard ? errors.DeliveryYard : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="MaxDraft"
                    label="Max Draft *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.MaxDraft && Boolean(errors.MaxDraft)}
                    helperText={touched.MaxDraft && errors.MaxDraft ? errors.MaxDraft : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="MaxDeadWg"
                    label="Max Dead Wg *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.MaxDeadWg && Boolean(errors.MaxDeadWg)}
                    helperText={touched.MaxDeadWg && errors.MaxDeadWg ? errors.MaxDeadWg : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="EEDI"
                    label="EEDI *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.EEDI && Boolean(errors.EEDI)}
                    helperText={touched.EEDI && errors.EEDI ? errors.EEDI : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="EEDISpeed"
                    label="EEDI Speed *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.EEDISpeed && Boolean(errors.EEDISpeed)}
                    helperText={touched.EEDISpeed && errors.EEDISpeed ? errors.EEDISpeed : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="EEXI"
                    label="EEXI *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.EEXI && Boolean(errors.EEXI)}
                    helperText={touched.EEXI && errors.EEXI ? errors.EEXI : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="EEXISpeed"
                    label="EEXI Speed *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.EEXISpeed && Boolean(errors.EEXISpeed)}
                    helperText={touched.EEXISpeed && errors.EEXISpeed ? errors.EEXISpeed : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="Owner"
                    label="Owner *"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Owner && Boolean(errors.Owner)}
                    helperText={touched.Owner && errors.Owner ? errors.Owner : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Field as={Switch} name="status" />}
                    label="Active"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default VesselForm;
