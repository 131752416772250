import React from "react";
import Grid from "@mui/material/Grid";
import "../styles/style.css";
import Header from "../components/header";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import SideMenu from "../components/sidemenu";
import { Drawer } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import ClientList from "../components/Client/ClientList";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "14px !important",
    textOverflow: "ellipsis",
  },
  boxsized: {
    width: "calc(100%)",
    display: "flex",
    overflowX: "auto",
    background: theme.palette.background.body,
  },
  sidebar: {
    width: "100px",
  },
  fleetover: {
    width: "calc(100% - 200px)",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Overview() {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  const toogleDrawer = (e) => {
    setState({ isOpen: !state.isOpen });
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Header toogleDrawer={toogleDrawer} />
      <DrawerHeader />
      <Grid
        component="main"
        sx={{ flexGrow: 1, p: 0 }}
        className={classes.boxsized}
      >
        <Hidden mdDown>
          <SideMenu open={state.isOpen} className={classes.sidebar} />
        </Hidden>

        <Hidden mdUp>
          <Drawer
            open={state.isOpen}
            onClose={() => setState({ isOpen: false })}
          >
            <SideMenu open={true} className={classes.sidebar} />
          </Drawer>
        </Hidden>

        <Grid
          style={
            state.isOpen
              ? { width: "calc(100% - 184px)" }
              : { width: "calc(100% - 48px)" }
          }
        >
          <ClientList />
        </Grid>
      </Grid>
    </Grid>
  );
}
