const fleets = [
  {
    "IMONo": "9836373",
    "Name": "BW NARA",
    "VesselType": 1001,
    "PortofRegistry": "Singapore",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Tsuneishi Cebu, Phillipines",
    "MaxDraft": 14.43,
    "MaxDeadWg": 81759,
    "EEDI": 3.46,
    "EEDISpeed": 14.57,
    "EEXI": 3.46,
    "EEXISpeed": 14.57,
    "Owner": "Viterra",
    "Charterer": "BW Maru",
    "ShipManager": "Maru Fleet",
    "AER": 3.42,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "A",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "12:18.9",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9860386",
    "Name": "WORLD CREST",
    "VesselType": 1001,
    "PortofRegistry": "Panama",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Oshima S/Y, Japan",
    "MaxDraft": 13.35,
    "MaxDeadWg": 62569,
    "EEDI": 3.54,
    "EEDISpeed": 14.15,
    "EEXI": 3.54,
    "EEXISpeed": 14.15,
    "Owner": "CCP",
    "Charterer": "Wailea Line Inc/FJ Shipping Co",
    "ShipManager": "Fukujin Kisen KK",
    "AER": 4.36,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "B",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "32:29.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9860403",
    "Name": "WORLD PRIZE",
    "VesselType": 1001,
    "PortofRegistry": "Isle of Man",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Oshima S/Y, Japan",
    "MaxDraft": 13.35,
    "MaxDeadWg": 62496,
    "EEDI": 3.53,
    "EEDISpeed": 13.96,
    "EEXI": 3.53,
    "EEXISpeed": 13.96,
    "Owner": "Cargill",
    "Charterer": "BW Dry Cargo",
    "ShipManager": "Synergy Maritime",
    "AER": 4.03,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "A",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "16:11.1",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9836476",
    "Name": "BW KOBE",
    "VesselType": 1001,
    "PortofRegistry": "Singapore",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Tsuneishi Cebu, Phillipines",
    "MaxDraft": 14.43,
    "MaxDeadWg": 81703,
    "EEDI": 3.49,
    "EEDISpeed": 14.46,
    "EEXI": 3.49,
    "EEXISpeed": 14.46,
    "Owner": "Viterra",
    "Charterer": "BW Maru PTE Ltd",
    "ShipManager": "Maru Fleet",
    "AER": 3.58,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "B",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "06:43.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9836567",
    "Name": "BW OSAKA",
    "VesselType": 1001,
    "PortofRegistry": "Singapore",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Tsuneishi Cebu, Phillipines",
    "MaxDraft": 14.43,
    "MaxDeadWg": 81796,
    "EEDI": 3.46,
    "EEDISpeed": 14.57,
    "EEXI": 3.46,
    "EEXISpeed": 14.46,
    "Owner": "Viterra",
    "Charterer": "BW Maru PTE Ltd",
    "ShipManager": "Maru Fleet",
    "AER": 3.56,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "B",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "11:26.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9875214",
    "Name": "WORLD DIANA",
    "VesselType": 1001,
    "PortofRegistry": "Isle of Man",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Oshima S/Y, Japan",
    "MaxDraft": 14.51,
    "MaxDeadWg": 82032,
    "EEDI": 3.34,
    "EEDISpeed": 14.28,
    "EEXI": 3.34,
    "EEXISpeed": 14.28,
    "Owner": "Bunge",
    "Charterer": "BW Dry Cargo PTE Ltd",
    "ShipManager": "OSM Maritime AS",
    "AER": 3.35,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "A",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "08:33.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9860374",
    "Name": "WORLD VIRTUE",
    "VesselType": 1001,
    "PortofRegistry": "Isle of Man",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Oshima S/Y, Japan",
    "MaxDraft": 13.35,
    "MaxDeadWg": 62569,
    "EEDI": 3.52,
    "EEDISpeed": 14.23,
    "EEXI": 3.52,
    "EEXISpeed": 14.23,
    "Owner": "Trafigura",
    "Charterer": "BW Dry Cargo PTE Ltd",
    "ShipManager": "OSM Maritime AS",
    "AER": 3.35,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "B",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "48:38.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9558294",
    "Name": "BERGE NYANGANI",
    "VesselType": 1001,
    "PortofRegistry": "Isle of Man",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Tsuneishi Cebu, Phillipines",
    "MaxDraft": 18.07,
    "MaxDeadWg": 180524,
    "EEDI": 0,
    "EEDISpeed": 0,
    "EEXI": 2.39,
    "EEXISpeed": 13.67,
    "Owner": "Trafigura",
    "Charterer": "BW Maritime PTE Ltd",
    "ShipManager": "Synergy Maritime PVT Ltd",
    "AER": 2.75,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "D",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "43:41.0",
    "OPEndDate": null,
    "IsActive": 1
  },
  {
    "IMONo": "9836488",
    "Name": "BW MATSUYAMA",
    "VesselType": 1001,
    "PortofRegistry": "Singapore",
    "DeliveryDate": "00:00.0",
    "DeliveryYard": "Tsuneishi Cebu, Phillipines",
    "MaxDraft": 14.43,
    "MaxDeadWg": 81810,
    "EEDI": 3.44,
    "EEDISpeed": 14.65,
    "EEXI": 3.44,
    "EEXISpeed": 14.65,
    "Owner": "Viterra",
    "Charterer": "BW Maru",
    "ShipManager": "Maru Fleet",
    "AER": 3.64,
    "OPStartDate": "00:00.0",
    "CO2Emit": 0,
    "DistanceTravel": 0,
    "TransportWork": 0,
    "CIIRate": "B",
    "AERCIIYear": 2022,
    "IsConfirmed": 1,
    "ISConfirmDate": "00:00.0",
    "OPEndDate": null,
    "IsActive": 1
  }
]
export default fleets;