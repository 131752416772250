import "../../styles/style.css";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "rsuite";
import { Box, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import apiService from '../../services/ApiService'
import urlService from "../../services/UrlService";
import AddClientModel from './AddClientModel';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import fleets from '../Constants/fleet';
import VesselForm from './AddFleetModel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmModel from "./DeleteConfirmModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4802a0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const TableLayoutContainer = styled(TableContainer)(() => ({
  height: '100%',
  overflow: 'scroll',
  width: '100%',
  maxHeight: 550
}));

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px !important",
    fontSize: "14px",
    marginLeft: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
  },
  imageIcon: {
    marginLeft: theme.spacing(1),
  },
  headerText: {
    paddingBottom: '20px',
  },
  container: {
    width: '100%',
  },
}));




const NewClient = ({ clientInfo }) => {
  const classes = useStyles();
  const [clientInformation, setClientInformation] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (clientInfo) {
      setClientInformation(clientInfo)
    }
  }, [clientInfo])



  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [fleetInfo, setFleetInfo] = React.useState('')

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    // items.push(formValues);
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setFleetInfo('')
  }

  const deleteClose = () => {
    setDeleteOpen(false)
  }

  const deleteSubmit = () => {
    setDeleteOpen(false)
  }

  const editFleet = (fleet) => {
    setFleetInfo(fleet)
    setOpen(true);
  }

  const deleteFleet = (client) => {
    setDeleteOpen(true)
  }


  return (
    <Grid style={{ padding: "20px" }}>
      <Grid container alignItems="center" className={classes.headerText}>
        <Grid item xs={6}>
          <Typography className="headertext" align="left">
            {clientInformation?.CompanyName} | Fleet List
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button type="button" className={classes.button} onClick={handleClickOpen}>
              <Typography>Add fleet</Typography>
              <Box sx={{ ml: 1 }}>
                <PersonAddAlt1Icon className={classes.imageIcon} />
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <TableLayoutContainer component={Paper}>
        {/* <AddClientModel open={open} handleClose={handleClose} onSubmit={onSubmit} /> */}
        <VesselForm open={open} handleClose={handleClose} onSubmit={onSubmit} fleetInfo={fleetInfo} />
        <DeleteConfirmModel title={'Are you sure you want to delete the fleet?'} open={deleteOpen} handleClose={deleteClose} onSubmit={deleteSubmit} />

        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Vessel Type</StyledTableCell>
              <StyledTableCell align="left">Port of Registry</StyledTableCell>
              <StyledTableCell align="left">Delivery Date</StyledTableCell>
              <StyledTableCell align="left">Delivery Yard</StyledTableCell>
              <StyledTableCell align="left">Max Draft</StyledTableCell>
              <StyledTableCell align="left">Max Dead Weight</StyledTableCell>
              <StyledTableCell align="left">EEDI</StyledTableCell>
              <StyledTableCell align="left">EEDI Speed</StyledTableCell>
              <StyledTableCell align="left">EEXI</StyledTableCell>
              <StyledTableCell align="left">EEXI Speed</StyledTableCell>
              <StyledTableCell align="left">Owner</StyledTableCell>
              <StyledTableCell align="left">Charterer</StyledTableCell>
              <StyledTableCell align="left">Ship Manager</StyledTableCell>
              <StyledTableCell align="left">AER</StyledTableCell>
              <StyledTableCell align="left">OP Start Date</StyledTableCell>
              <StyledTableCell align="left">CO2 Emissions</StyledTableCell>
              <StyledTableCell align="left">Distance Travelled</StyledTableCell>
              <StyledTableCell align="left">Transport Work</StyledTableCell>
              <StyledTableCell align="left">CII Rate</StyledTableCell>
              <StyledTableCell align="left">AER/CII Year</StyledTableCell>
              <StyledTableCell align="left">Is Confirmed</StyledTableCell>
              <StyledTableCell align="left">IS Confirm Date</StyledTableCell>
              <StyledTableCell align="left">OP End Date</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left"> Action </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fleets
              .map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: '10px 16px' }}
                >
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.Name}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.VesselType}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.PortofRegistry}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.DeliveryDate}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.DeliveryYard}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.MaxDraft}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.MaxDeadWg}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.EEDI}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.EEDISpeed}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.EEXI}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.EEXISpeed}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.Owner}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.Charterer}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.ShipManager}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.AER}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.OPStartDate}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.CO2Emit}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.DistanceTravel}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.TransportWork}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.CIIRate}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.AERCIIYear}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.IsConfirmed ? 'Yes' : 'No'}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.ISConfirmDate}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.OPEndDate}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>{row.IsActive ? 'Active' : 'Inactive'}</StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => editFleet(row)}>
                      <EditIcon />
                    </span>
                    <span style={{ cursor: 'pointer' }} onClick={() => deleteFleet(row)}>
                      <DeleteIcon />
                    </span>
                  </StyledTableCell></StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableLayoutContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={fleets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default NewClient;
