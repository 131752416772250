const sscLine = [
  "3.90",
  "4.04",
  "4.06",
  "4.08",
  "4.11",
  "4.19",
  "4.17",
  "4.16",
  "4.17",
  "4.16",
  "4.17",
  "4.18",
  "4.19",
  "4.19",
  "4.22",
  "4.26",
  "4.30",
  "4.29",
  "4.31",
  "4.34",
  "4.40",
  "4.41",
  "4.49",
  "4.66",
  "5.02",
  "5.26",
  "5.37",
  "5.66",
  "5.69",
  "5.73",
  "5.74",
  "5.77",
  "5.82",
  "5.86",
  "5.91",
  "5.91",
  "5.83",
  "5.78",
  "5.78",
  "5.81",
  "5.77",
  "5.74",
  "5.73",
  "5.75",
  "5.79",
  "5.82",
  "5.83",
  "5.80",
  "5.84",
  "5.88",
  "6.16",
  "6.38",
  "6.43",
  "6.71",
  "6.83",
  "6.87",
  "6.87",
  "6.88",
  "6.92",
  "6.96",
  "7.00",
  "7.04",
  "7.07",
  "7.11",
  "7.15",
  "7.19",
  "7.23",
  "7.27",
  "7.30",
  "7.35",
  "7.39",
  "7.43",
  "7.46",
  "7.50",
  "7.53",
  "7.57",
  "7.60",
  "7.64",
  "7.67",
  "7.71",
  "7.74",
  "7.77",
  "7.82",
  "7.86",
  "7.90",
  "7.94",
  "7.97",
  "7.98",
  "7.98",
  "8.01",
  "8.05",
  "8.08",
  "8.12",
  "8.17",
  "8.20",
  "8.20",
  "8.12",
  "7.94",
  "7.89",
  "7.69",
  "7.53",
  "7.36",
  "7.36",
  "7.38",
  "7.40",
  "7.43",
  "7.44",
  "7.43",
  "7.44",
  "7.47",
  "7.50",
  "7.51",
  "7.51",
  "7.67",
  "7.86",
  "8.05",
  "8.26",
  "8.45",
  "8.64",
  "8.84",
  "9.04"
]

export default sscLine;