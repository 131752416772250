import React from "react";
// import { Counter } from './features/counter/Counter';
import "./App.css";
import Router from "./router/router";
import AppTheme from "./App.theme";

function App() {
  return (
    <div className="App">
      <AppTheme>
        <Router />
      </AppTheme>
    </div>
  );
}

export default App;
