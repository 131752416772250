const siteUrls = {
  fleetOverview: 'FleetOverview',
  co2EmissionChart: 'VesselCIIGraph',
  emissionsInPeriod: 'VesselEmission',
  vesselDistance: 'VesselDistance',
  vesselInfo: 'VesselInfo',
  VesselVoyageRoutes: 'VesselVoyageRoutes',
  VesselFOC: 'VesselFOC',
  VesselSSCGraph: 'VessslSSCGraph',
  VesselPPGraph: 'VesselPPGraph',
  generateReport: 'ReportGenerate',
  authentication:'Authentication',
  VesselSpeedPerformance:'VesselSpeedPerformance'
}

export default siteUrls;
