import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    width: "max-content",
    backgroundColor: "#4802a0",
    minHeight: "calc(100vh - 56px)",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vh - 16px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  linkStyle: {
    textDecoration: "none",
    color: "black",
  },
  listContainer: {
    width: "auto",
  },
  iconsContainer: {
    minWidth: 20,
  },
  imageIcon: {
    width: "20px",
    height: "20px",
    fill: "#fff",
  },
  listItem: {
    height: 40,
    padding: "24px",
    "&:hover": {
      backgroundColor: "#2b435e",
    },
  },
  listItemActive: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SideMenu = ({ open, toggleDrawer }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const Menus = [
    {
      name: "Fleet Overview",
      icon: <DirectionsBoat className={classes.imageIcon} />,
      path: "/fleetoverview",
    },
    // Additional menu items...
        // {
    //   name: "User config",
    //   icon: <ManageAccountsIcon className={classes.imageIcon} />,
    //   path: "/new-user",
    // },
    // {
    //   name: "Client config",
    //   icon: <SettingsIcon className={classes.imageIcon} />,
    //   path: "/new-client",
    // },
  ];

  return (
    <Grid container className={classes.menuContainer} style={!open ? { width: "auto" } : {}}>

      {/* <Grid item className={classes.listContainer}>
        <List style={{ padding: 0 }}>
          {Menus.map((menu, index) => (
            <ListItem
              key={index}
              className={classes.listItem}
              onClick={() => {
                navigate(menu.path);
                // toggleDrawer();
              }}
              button
            >
              <ListItemIcon className={classes.iconsContainer}>
                {menu.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={menu.name}
                  style={{ color: "#ffffff", paddingLeft: "20px" }}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Grid> */}
    </Grid>
  );
};

export default SideMenu;