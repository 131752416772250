import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "../../styles/style.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "12px !important",
    textOverflow: "ellipsis",
    nowrap: "nowrap",
     color:"#000 !important"
  },
  header: {
    color: theme.palette.common.text,
    fontSize: "1.2em",
    textOverflow: "ellipsis",
    fontWeight: "bold",
     color:"#000 !important"
  },
  container: {
    "border-radius": "6px",
    padding: "8px 10px",
    "margin-top": "10px",
    "box- shadow":
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      height:"242px"
  },
  borderShadow: {
    border: "1px solid rgb(215 213 223)",
  },
}));

export default function MyApp(props) {
  const classes = useStyles();
  const dataset = props.data;
  const isPrintPreview = dataset[0]?.isPrintPage ? true : false;

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };
  const formatNumbers = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Grid
      container
      className={`${classes.container} ${
        !isPrintPreview ? classes.borderShadow : ""
      }`}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          className={classes.header}
          textAlign="left"
          style={{ paddingBottom: "10px" }}
        >
         <span style={{fontSize:"13px",fontWeight:600}}>{dataset[0].title}</span>
        </Typography>
        {dataset[0].tablehead.map((val, index) => (
          <Grid
            container
            key={index}
            style={{
              borderBottom: "1px solid rgb(13, 1, 65)",
              marginBottom: "6px",
            }}
          >
            <Grid item xs={3} alignContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data1 && val.data1.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data2 && val.data2.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data3 && val.data3.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data4 && val.data4.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data5 && val.data5.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={1.8} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data6 && val.data6.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {dataset[0].tabledata.map((val, index) => (
          <Grid container key={index} style={{ padding: "6px 0px 0px 0px" }}>
            <Grid item xs={3} alignContent="right" display="flex">
              <Typography
                noWrap="nowrap"
                variant="h6"
                className={classes.text}
                style={{ textAlign: "left", width: "100%" }}
              >
                {val.data1}
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data2 && formatNumbers(roundToDecimals(val.data2, 0))}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data3 && formatNumbers(roundToDecimals(val.data3, 0))}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data4 && formatNumbers(roundToDecimals(val.data4, 0))}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data5 && formatNumbers(roundToDecimals(val.data5, 0))}
              </Typography>
            </Grid>
            <Grid item xs={1.8} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data6 && formatNumbers(roundToDecimals(val.data6, 0))}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {dataset[0].tablefooter.map((val, index) => (
          <Grid
            container
            key={index}
            style={{ borderTop: "1px solid rgb(13, 1, 65)", marginTop: "6px" }}
          >
            <Grid item xs={3} alignContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data1}
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data2 && formatNumbers(roundToDecimals(val.data2, 0))}
                {/* {val.data2 && val.data2.toLocaleString()} */}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
              {val.data3 && formatNumbers(roundToDecimals(val.data3, 0))}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data4 &&  formatNumbers(roundToDecimals(val.data4, 0))}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
              {val.data5 && formatNumbers(roundToDecimals(val.data5, 0))} 
              </Typography>
            </Grid>
            <Grid item xs={1.8} justifyContent="right" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data6 && formatNumbers(roundToDecimals(val.data6, 0))}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
