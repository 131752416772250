import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "../../styles/style.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.text,
    fontSize: "14px !important",
    textOverflow: "ellipsis",
     color:"#000 !important"
  },
  header: {
    color: theme.palette.common.text,
    fontSize: "1.2em",
    textOverflow: "ellipsis",
    fontWeight: "bold",
     color:"#000 !important"
  },
  container: {
    'border-radius': '6px',
    'padding': '8px 10px',
    'margin-top': '16px',
    'box- shadow': '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  borderShadow: {
    'border': '1px solid rgb(215 213 223)',
  }
}))
const roundToDecimals = (number, decimals) => {
  const factor = Math.pow(10, decimals);
  return (Math.round(number * factor) / factor).toFixed(decimals);
};

export default function MyApp(props) {
  const classes = useStyles();
  const dataset = props.data;
  const isPrintPreview = dataset[0]?.isPrintPage ? true : false

  return (
    <Grid container className={`${classes.container} ${!isPrintPreview ? classes.borderShadow : ''}`}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          className={classes.header}
          textAlign="left"
          style={{ paddingBottom: "10px" }}
        >
          {dataset[0].title}
        </Typography>
        {dataset[0].tablehead.map((val, index) => (
          <Grid
            container
            key={index}
            style={index === 0 ? {} : { borderBottom: "1px solid rgb(13, 1, 65)" }}
          >
            <Grid item xs={3} alignContent="left" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data1}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data2}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data3}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data4}
              </Typography>
            </Grid>
          </Grid>
        ))}

        {dataset[0].tabledata.map((val, index) => (
          <Grid container key={index} style={{ padding: "6px 0px 0px 0px" }}>
            <Grid item xs={3} alignContent="left" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data1}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data2 && roundToDecimals(val.data2, 1)}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data3 >= 0 && roundToDecimals(val.data3, 1)}
              </Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Typography variant="h6" className={classes.text}>
                {val.data4 == 0 ? "" : val.data4 && roundToDecimals(val.data4, 0)}{val.data4 == 0 ? "" :'%'}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
