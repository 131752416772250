import "../../styles/style.css";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "rsuite";
import { Box, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import apiService from '../../services/ApiService'
import urlService from "../../services/UrlService";
import AddUserModel from './AddUserModel';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmModel from "./DeleteConfirmModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4802a0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const TableLayoutContainer = styled(TableContainer)(() => ({
  height: '100%',
  overflow: 'scroll',
  width: '100%',
  maxHeight: 550
}));

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px !important",
    fontSize: "14px",
    marginLeft: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
  },
  imageIcon: {
    marginLeft: theme.spacing(1),
  },
  headerText: {
    paddingBottom: '20px',
  },
  container: {
    width: '100%',
  },
}));

const defaultClients = [
  {
    AccountName: 'Murugan',
    EmailID: 'mch@oceanpass.com',
    Role: 'Admin',
    IsActive: '1',
    Vessels: ['Ves1', 'Ves2'],
  },
  {
    AccountName: 'Michel',
    EmailID: 'mhs@oceanpass.com',
    Role: 'Admin',
    IsActive: '1',
    Vessels: ['Ves1', 'Ves2'],
  },
  {
    AccountName: 'Oscar',
    EmailID: 'oscar@oceanpass.com',
    Role: 'User',
    IsActive: '1',
    Vessels: ['Ves1', 'Ves2'],
  },
]


const UserList = ({ clientInfo }) => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clients, setClients] = React.useState(defaultClients);
  const [userInfo, setUserInfo] = React.useState('')
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [clientInformation, setClientInformation] = useState(null)

  useEffect(() => {
    if (clientInfo) {
      setClientInformation(clientInfo)
    }
  }, [clientInfo])

  const handleClickOpen = () => {
    setUserInfo('')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editUser = (client) => {
    setUserInfo(client)
    setOpen(true);
  }

  const deleteUser = (client) => {
    setDeleteOpen(true)
  }

  const onSubmit = (formValues) => {
    const items = clients;
    items.push(formValues);
    setClients(items)
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteClose = () => {
    setDeleteOpen(false)
  }

  const deleteSubmit = () => {
    setDeleteOpen(false)
  }


  return (
    <Grid style={{ padding: "20px" }}>
      <Grid container alignItems="center" className={classes.headerText}>
        <Grid item xs={6}>
          <Typography className="headertext" align="left">
            {clientInformation?.CompanyName} | Users List
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button type="button" className={classes.button} onClick={handleClickOpen}>
              <Typography>Add User</Typography>
              <Box sx={{ ml: 1 }}>
                <PersonAddAlt1Icon className={classes.imageIcon} />
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <TableLayoutContainer component={Paper} >
        <DeleteConfirmModel title={'Are you sure you want to delete the user?'} open={deleteOpen} handleClose={deleteClose} onSubmit={deleteSubmit} />
        <AddUserModel open={open} handleClose={handleClose} onSubmit={onSubmit} userInfo={userInfo} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
          <TableHead >
            <TableRow>
              <StyledTableCell align="left"> AccountName </StyledTableCell>
              <StyledTableCell align="left"> Email Id </StyledTableCell>
              <StyledTableCell align="left"> Role </StyledTableCell>
              <StyledTableCell align="left"> Status </StyledTableCell>
              <StyledTableCell align="left"> Action </StyledTableCell>
            </TableRow>
          </TableHead>
          {/* </Table> */}
          {/* <div style={{ overflow: 'auto', height: '400px' }}> */}
          {/* <Table style={{ tableLayout: 'fixed' }}> */}

          <TableBody>
            {clients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: '10px 16px' }}
                >
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    {row.AccountName}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    {row.EmailID}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    {row.Role}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    {row.IsActive == 1 ? 'Acive' : 'Inactive'}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ padding: '10px 16px' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => editUser(row)}>
                      <EditIcon />
                    </span>
                    <span style={{ cursor: 'pointer' }} onClick={() => deleteUser(row)}>
                      <DeleteIcon />
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        {/* </div> */}
      </TableLayoutContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={clients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default UserList;
