import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ProviderFormModal = ({ open, handleClose, onSubmit, clientInfo }) => {
  const validationSchema = Yup.object({
    CompanyCode: Yup.string().required('Company Code is required'),
    CompanyName: Yup.string().required('Company Name is required'),
    ContactPerson: Yup.string().required('Contact Person is required'),
    Address: Yup.string(),
    City: Yup.string(),
    State: Yup.string(),
    Country: Yup.string().required('Country is required'),
    ZipCode: Yup.string(),
    TelNo: Yup.string(),
    EmailId: Yup.string().email('Invalid email format'),
    Website: Yup.string(),
    ProvCat: Yup.string(),
    IsActive: Yup.boolean().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      ProviderID: '',
      CompanyCode: '',
      CompanyName: '',
      ContactPerson: '',
      Address: '',
      City: '',
      State: '',
      Country: '',
      ZipCode: '',
      TelNo: '',
      EmailId: '',
      Website: '',
      ProvCat: '',
      IsActive: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm(); // Reset the form fields
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    handleClose();
  };

  useEffect(() => {
    if (clientInfo) {
      formik.setValues({
        ProviderID: clientInfo?.ProviderID,
        CompanyCode: clientInfo?.CompanyCode,
        CompanyName: clientInfo?.CompanyName,
        ContactPerson: clientInfo?.ContactPerson,
        Address: clientInfo?.Address,
        City: clientInfo?.City,
        State: clientInfo?.State,
        Country: clientInfo?.Country,
        ZipCode: clientInfo?.ZipCode,
        TelNo: clientInfo?.TelNo,
        EmailId: clientInfo?.EmailId,
        Website: clientInfo?.Website,
        ProvCat: clientInfo?.ProvCat,
        Status: clientInfo?.Status,
        IsActive: clientInfo?.IsActive,
      });
    }
  }, [clientInfo]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{clientInfo ? 'Edit Client' : 'Add Client'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Code"
                name="CompanyCode"
                value={formik.values.CompanyCode}
                onChange={formik.handleChange}
                error={formik.touched.CompanyCode && Boolean(formik.errors.CompanyCode)}
                helperText={formik.touched.CompanyCode && formik.errors.CompanyCode}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Company Name"
                name="CompanyName"
                value={formik.values.CompanyName}
                onChange={formik.handleChange}
                error={formik.touched.CompanyName && Boolean(formik.errors.CompanyName)}
                helperText={formik.touched.CompanyName && formik.errors.CompanyName}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Contact Person"
                name="ContactPerson"
                value={formik.values.ContactPerson}
                onChange={formik.handleChange}
                error={formik.touched.ContactPerson && Boolean(formik.errors.ContactPerson)}
                helperText={formik.touched.ContactPerson && formik.errors.ContactPerson}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Address"
                name="Address"
                value={formik.values.Address}
                onChange={formik.handleChange}
                error={formik.touched.Address && Boolean(formik.errors.Address)}
                helperText={formik.touched.Address && formik.errors.Address}
                margin="normal"

              />
              <TextField
                fullWidth
                label="City"
                name="City"
                value={formik.values.City}
                onChange={formik.handleChange}
                error={formik.touched.City && Boolean(formik.errors.City)}
                helperText={formik.touched.City && formik.errors.City}
                margin="normal"

              />
              <TextField
                fullWidth
                label="State"
                name="State"
                value={formik.values.State}
                onChange={formik.handleChange}
                error={formik.touched.State && Boolean(formik.errors.State)}
                helperText={formik.touched.State && formik.errors.State}
                margin="normal"
                required
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.IsActive}
                    onChange={formik.handleChange}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Country"
                name="Country"
                value={formik.values.Country}
                onChange={formik.handleChange}
                error={formik.touched.Country && Boolean(formik.errors.Country)}
                helperText={formik.touched.Country && formik.errors.Country}
                margin="normal"

              />
              <TextField
                fullWidth
                label="Zip Code"
                name="ZipCode"
                value={formik.values.ZipCode}
                onChange={formik.handleChange}
                error={formik.touched.ZipCode && Boolean(formik.errors.ZipCode)}
                helperText={formik.touched.ZipCode && formik.errors.ZipCode}
                margin="normal"

              />
              <TextField
                fullWidth
                label="Telephone No"
                name="TelNo"
                value={formik.values.TelNo}
                onChange={formik.handleChange}
                error={formik.touched.TelNo && Boolean(formik.errors.TelNo)}
                helperText={formik.touched.TelNo && formik.errors.TelNo}
                margin="normal"

              />
              <TextField
                fullWidth
                label="Email ID"
                name="EmailId"
                value={formik.values.EmailId}
                onChange={formik.handleChange}
                error={formik.touched.EmailId && Boolean(formik.errors.EmailId)}
                helperText={formik.touched.EmailId && formik.errors.EmailId}
                margin="normal"

              />
              <TextField
                fullWidth
                label="Website"
                name="Website"
                value={formik.values.Website}
                onChange={formik.handleChange}
                error={formik.touched.Website && Boolean(formik.errors.Website)}
                helperText={formik.touched.Website && formik.errors.Website}
                margin="normal"

              />
              <TextField
                fullWidth
                label="Provider Category"
                name="ProvCat"
                value={formik.values.ProvCat}
                onChange={formik.handleChange}
                error={formik.touched.ProvCat && Boolean(formik.errors.ProvCat)}
                helperText={formik.touched.ProvCat && formik.errors.ProvCat}
                margin="normal"

              />

            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProviderFormModal;
