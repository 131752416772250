import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";
import sscLine from "../Constants/SSCLine";

function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([]);
  const [maxPoint, setMaxPoint] = useState();
  const [minPoint, setMinPoint] = useState();
  const [dataCheck, setDataCheck] = useState();
console.log(minPoint,"maxPoint",maxPoint)
  const SSCGGraphConfig = {
    chart: {
      backgroundColor: null,
      type: 'line',
      // height:260,
     // width:700,
    },
    title: {
      text: `Sea Cargo Charter (SCC)`,
      align: 'left',
      useHTML: true,
      style: {
        height: '35px'
      }
    },
    subtitle: {
      text: props.additionalData ? `
        <div style="paddingBottom:10px">
          <span style="
            text-indent: 120px;
            color: black;
            font-weight: bold;
            font-size: 14px;
            display: inline-block;
          ">EEOIe = ${props.additionalData.eeoi || 0} gCO2e/mtxnm</span>
          &nbsp;
          <div style="
            color: black;
            font-weight: bold;
            font-size: 14px;
            display: inline-block;
            text-indent: 55px;
          ">
            SCC Alignment = <span style=" color: white;
            background: ${props.additionalData.scc > 0 ? '#ff3300' : '#2cc932'};
            padding: 7px 10px;
            border-radius:10px;
          ">${props.additionalData.scc || 0}%</span>
          </div>
        </div>
      ` : '',
      align: 'left',
      useHTML: true
    },
    xAxis: {
      gridLineWidth: 1, // Set grid line width for yAxis
      gridLineColor: '#cccccc', // Set grid line color for yAxis
      type: 'datetime',
      title: {
        text: ''
      }
    },
    yAxis: {
      gridLineWidth: 1, // Set grid line width for yAxis
      gridLineColor: '#cccccc', // Set grid line color for yAxis
      title: {
        text: 'EEOIe [gCO2e/mtxnm]'
      },
      max: maxPoint,
      min: minPoint,
      tickInterval: Math.round((maxPoint - minPoint) / 2) / 2,
      labels: {
        formatter: function() {
          const roundedValue = Math.round(this.value * 2) / 2; 
      return roundedValue !== 0 ? roundedValue.toFixed(1) : ''; 
        },
      },
    },
    
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemMarginTop: 10,
      itemMarginBottom: 0
    },
    credits: {
      enabled: false
    },
    series: dataSeries,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800
        }
      }]
    }
  };

  const parseData = (data, key) => {
    return data
      .map(point => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };

  const getVesselSSCGraph = async () => {
    try {
      const vesselSSCGDataPoints = await apiService.get(`${urlService.VesselSSCGraph}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`);

      const dates = vesselSSCGDataPoints.map((point) => point.yDate);
      const parseSSCLineData = dates.map((date, index) => [Date.parse(date), Number(sscLine[index])]);
      const YxUp = parseData(vesselSSCGDataPoints, 'yxUp');
      const YxLow = parseData(vesselSSCGDataPoints, 'yxLow');
      const dataSeriesArray = [
        {
          name: 'SCC Minimum',
          data: parseData(vesselSSCGDataPoints, 'sccMinimum'),
          color: '#AA00FF',
          marker: {
            enabled: false
          }
        }, {
          name: 'SCC Striving',
          data: parseData(vesselSSCGDataPoints, 'sccStriving'),
          color: '#0000FF',
          marker: {
            enabled: false
          }
        }, {
          name: 'EEOIe W2W',
          data: parseData(vesselSSCGDataPoints, 'ciicO2'),
          color: '#4600A0',
          type: 'line',
          marker: {
            enabled: false
          }
        }
      ];
      setDataCheck(dates);
      setDataSeries(dataSeriesArray);
      setMaxPoint(YxUp[0][1]);
      setMinPoint(YxLow[0][1]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getVesselSSCGraph();
  }, []);

  return (
    <div  className="charts-container center" style={dataCheck && dataCheck.length < 1 ? { display: "none", marginTop: "5px" ,height:"420px"} : { marginTop:"10px",  marginLeft:"10px",height:"405px" }}>
      <>
        <HighchartsReact highcharts={Highcharts} options={SSCGGraphConfig} />
      </>
    </div>
  );
}

export default Co2EmissionChart;