import axios from 'axios';
const baseURL = 'https://opservices.navisoft.io/api';



async function get(url) {
  try {
    const response = await axios.get(`${baseURL}/${url}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


async function post(url, payload) {
  try {
    const response = await axios.post(`${baseURL}/${url}`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const apiService = {
  get,
  post,
}
export default apiService;