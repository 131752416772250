import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";
import { useParams, useSearchParams } from "react-router-dom";

const MulticolorMultilineStringMap = () => {
  const { vesselId } = useParams();
  const [searchParams] = useSearchParams();
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const mapRef = useRef(null);
  const sourceLayersRef = useRef({});

  const getRandomColor = () => {
    //const colors = ['red', 'green', 'orange', 'blue'];
    const colors = ['#4600A0'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const groupVoyageRoutes = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const formatCoordinates = (coordinates = []) => {
    return coordinates.map(loc => [loc.long, loc.lat]);
  };

  const getVesselVoyageRoutes = async () => {
    const fromDate = moment(searchParams.get('start')).format('DD-MMM-YYYY HH:mm');
    const toDate = moment(searchParams.get('end')).format('DD-MMM-YYYY HH:mm');
    try {
      const data = await apiService.get(`${urlService.VesselVoyageRoutes}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`);
      if (data.length) {
        const groupedData = groupVoyageRoutes(data, 'voyageNo');
        const finalCoordinates = Object.keys(groupedData).map(key => ({
          coordinates: formatCoordinates(groupedData[key]),
          color: getRandomColor(),
        }));
        setMapCoordinates(finalCoordinates);
      } else {
        console.warn("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching vessel voyage routes:", error);
    }
  };

  useEffect(() => {
    getVesselVoyageRoutes();
  }, [vesselId, searchParams]);

  useEffect(() => {
    if (!mapRef.current) {
      mapboxgl.accessToken = "pk.eyJ1IjoiaXRlY2hkZXNrIiwiYSI6ImNsbzB2Ym90ZzAyOHkycG1wbGdzbDkwdHYifQ.m9ESpZNzQefxh1rRRs4tXA";
      mapRef.current = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [37.551544, 19.412756],
        zoom: 0.55,
        showAttribution: false,
        preserveDrawingBuffer: true,
        attributionControl: false, // Disable attribution control
      });

      mapRef.current.on("load", () => {
        console.log("Map loaded");
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef.current && mapCoordinates.length > 0) {
      if (mapRef.current.isStyleLoaded()) {
        updateMapLayers(mapCoordinates);
      } else {
        mapRef.current.once("styledata", () => {
          updateMapLayers(mapCoordinates);
        });
      }
    }
  }, [mapCoordinates]);

  const updateMapLayers = (coordinates) => {
    coordinates.forEach((segment, index) => {
      const sourceId = `multilineStringSource${index}`;
      const layerId = `multilineStringLayer${index}`;

      if (!sourceLayersRef.current[layerId]) {
        mapRef.current.addSource(sourceId, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: segment.coordinates,
            },
          },
        });

        mapRef.current.addLayer({
          id: layerId,
          type: "line",
          source: sourceId,
          paint: {
            "line-color": segment.color,
            "line-width": 2,
          },
        });

        

        sourceLayersRef.current[layerId] = true;
      } else {
        mapRef.current.getSource(sourceId).setData({
          type: "Feature",
          properties: {
            
          },
          geometry: {
            type: "LineString",
            coordinates: segment.coordinates,
          },
        });

        


        
      }
    });


    


  };

  return <div id="map" style={{ width: "100%", height: "350px",marginTop:"10px" }}></div>;
};

export default MulticolorMultilineStringMap;