import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import "../styles/style.css";
import { styled } from "@mui/material/styles";
import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, CardHeader, Collapse, FormGroup, } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import DataTableWithPieChart from "../components/Datatables/DataTableWithPieChart";
import SpeedUtilizationDataTable from "../components/Datatables/SpeedUtilizationDataTable";
import FuelConsumptionDataTable from "../components/Datatables/FuelConsumptionDataTable";
import EmissionsAndVesselEffDataTable from "../components/Datatables/EmissionsAndVesselEffDataTable";
import Header from "../components/header";
import Map from "../components/Charts/Map";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import SideMenu from "../components/sidemenu";
import { Drawer } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Co2EmissionChart from "../components/Charts/Co2EmissionGraph";
import VesselSSCGraph from "../components/Charts/VesselSSCGraph";
import VesselPPGraph from "../components/Charts/VesselPPGraph";
import apiService from "../services/ApiService";
import urlService from "../services/UrlService";
import moment from "moment";
import SpeedInstructionGraph from "../components/Charts/SpeedInstructionGraph";
import Co2EmissionChart1 from "../components/Charts/Co2EmissionGraph1";
var htmlToImage = require("html-to-image");

const useStyles = makeStyles((theme) => ({
  midContainer: {
    background: theme.palette.background.body,
  },
  sidebar: {
    width: "10px",
  },
  fleetover: {
    width: "calc(100% - 200px)",
  },
  boxsized: {
    width: "calc(100%)",
    display: "flex",
    overflowX: "auto",
    background: theme.palette.background.body,
  },
  button: {
    width: "150px",
    borderRadius: "20px !important",
    fontSize: "14px",
    marginTop: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize !important",
  },

  imageIcon: {
    width: "20px",
    height: "20px",
    fill: "#fff",
  },
  widthfull: {
    width: "100%",
  },
  header: {
    fontSize: "1.2em !important",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function DetailPage() {

  const disCoveredPeriodInit = [
    {
      title: "Distance Covered in Period [nautical miles]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        toolbar: "false",
        pieSliceText: "none",
        align: "center",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const timeDistributionInit = [
    {
      title: "Time Distribution in Period [Days]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        pieSliceText: "none",
        align: "center",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const DWTUtilInit = [
    {
      title: "Average Speed at Sea and DWT Utilization:",
      tablehead: [
        {
          data1: "",
          data2: "Speed Over Ground",
          data3: "Instructed Speed",
          data4: "DWT Utilization",
        },
        {
          data1: "",
          data2: "[kn]",
          data3: "[kn]",
          data4: "[% of max DWT]",
        },
      ],
      tabledata: [],
    },
  ];
  
  const cargoDeliveredPeriodInit = [
    {
      title: "Cargo Delivered in Period [mt]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        pieSliceText: "none",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const fuelConsumptionInit = [
    {
      title: "Energy Consumption [mt]:",
      tablehead: [
        {
          data1: "",
          data2: "HSHFO",
          data3: "VLSFO",
          data4: "ULSFO",
          data5: "MGO/MDO",
          data6: "Total",
        },
      ],
      tabledata: [
        {
          data1: "Main engine",
          data2: "0",
          data3: "4,945",
          data4: "0",
          data5: "324",
          data6: "5,269",
        },
        {
          data1: "Aux. engine",
          data2: "0",
          data3: "4,96",
          data4: "0",
          data5: "146",
          data6: "642",
        },
        {
          data1: "Aux. bailer",
          data2: "0",
          data3: "79",
          data4: "0",
          data5: "47",
          data6: "127",
        },
        {
          data1: "Various",
          data2: "0",
          data3: "0",
          data4: "0",
          data5: "0",
          data6: "0",
        },
      ],
      tablefooter: [
        {
          data1: "Total",
          data2: "0",
          data3: "0",
          data4: "0",
          data5: "0",
          data6: "0",
        },
      ],
    },
  ];
  
  const vesselEffRatingInit = [
    {
      title: "Vessel Efficiency Ratings:",
      tablehead: [
        {
          data1: "Vessel Type",
          data2: "",
        },
      ],
      tabledata: [
        {
          data1: "Deadweight (mt)",
          data2: "0",
        },
        {
          data1: `EEDI (gCO<span style={{ fontSize: "11px" }}>2</span> /mtxnm)`,
          data2: "0",
        },
        {
          data1: "EEDI Vpsf(knots)",
          data2: "0",
        },
        {
          data1: "EEXI (gCO2/mtxnm)",
          data2: "0",
        },
        {
          data1: "EEXI Vref(knots)",
          data2: "0",
        },
      ],
    },
  ];
  
  const emissionPeriodInitialData = [
    {
      title: "Emissions in period [mt]:",
      tabledata: [
        {
          data1: "",
          data2: "",
          data3: "",
        },
      ],
    },
  ];

  const classes = useStyles();
  const printRef = React.useRef();
  const navigate = useNavigate();
  const { vesselId } = useParams();
  const [searchParams] = useSearchParams();
  const [vesselInfo, setVesselInfo] = useState({
    imoNo: "",
    vesselName: "",
    vesselType: "",
    deadWeight: 0,
    rightRate: "",
    eedi: 0,
    eediVpsf: 0,
    eexi: 0,
    eexiVref: 0,
  });
  const location = useLocation();

  const additionalData = location.state?.additionalData;

  const [emissionPeriodTable, setEmissionPeriodTable] = useState(
    emissionPeriodInitialData
  );
  const [vesselEffRatingTable, setVesselEffRatingTable] =
    useState(vesselEffRatingInit);
  const [disCoveredPeriod, setDisCoveredPeriod] =
    useState(disCoveredPeriodInit);
  const [timeDistribution, setTimeDistribution] =
    useState(timeDistributionInit);
  const [cargoDeliveredPeriod, setCargoDeliveredPeriod] = useState(
    cargoDeliveredPeriodInit
  );
  const [fuelConsumption, setFuelConsumption] = useState(fuelConsumptionInit);
  const [speedUtilization, setSpeedUtilization] = useState(DWTUtilInit);

  const fromDate = moment(additionalData.dateRange[0]).format(
    "DD-MMM-YYYY HH:mm"
  );
  const toDate = moment(additionalData.dateRange[1]).format(
    "DD-MMM-YYYY HH:mm"
  );

  const groupDistance = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const [state, setState] = useState({
    isOpen: false,
  });

  const toogleDrawer = (e) => {
    setState({ isOpen: !state.isOpen });
  };

  const handleCapture = () => {

    navigate(`/print/${vesselId}?start=${fromDate}&end=${toDate}`, {
      state: {
        additionalData: additionalData // Replace with your actual data
      },
    });

    // navigate(`/print/${vesselId}?start=${fromDate}&end=${toDate}`);
  };

  const redirectoverview = () => {
    navigate(`/fleetoverview`, {
      state: {
        additionalData: { dateRangeback: additionalData.dateRange }, // Replace with your actual data
      },
    });
  };
  const GCO2 = () => (
    <>
      [gCO<span style={{ fontSize: "11px" }}>2</span> /mtxnm]
    </>
  );

  const getVesselInfo = async () => {
    try {
      const data = await apiService.get(
        `${urlService.vesselInfo}?VesseID=${vesselId}`
      );
      if (data.length) {
        const dataPoints = data[0];
        setVesselInfo(dataPoints);
        setVesselEffRatingTable([
          {
            title: "Vessel Efficiency Ratings:",
            tablehead: [
              {
                data1: "Vessel Type",
                data2: dataPoints.vesselType,
              },
            ],
            tabledata: [
              {
                data1: "DWT",
                data2: "[mt]",
                data3: dataPoints.deadWeight,
              },
              {
                data1: "EEDI",
                data2: <GCO2 />,
                data3: dataPoints.eedi,
              },
              {
                data1: "EEDI Vref",
                data2: "[knots]",
                data3: dataPoints.eediVpsf,
              },
              {
                data1: "EEXI ",
                data2: <GCO2 />,
                data3: dataPoints.eexi,
              },
              {
                data1: "EEXI Vref",
                data2: "[knots]",
                data3: dataPoints.eediVpsf,
              },
            ],
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVesselEmissionPeriod = async () => {
    try {
      const data = await apiService.get(
        `${urlService.emissionsInPeriod}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      if (data.length) {
        const formattedData = data.map((em) => ({
          data1: em.emissionName,
          data2: em.emissionCat,
          data3: roundToDecimals(em.emission, 0),
        }));
        setEmissionPeriodTable([
          {
            title: "Emissions in Period [mt]:",
            tabledata: formattedData,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setDistanceCovered = (distanceCovered = []) => {
    const totalDis = distanceCovered.reduce(
      (n, { distanceVal1 }) => n + distanceVal1,
      0
    );
    const tableData = distanceCovered
      .map((dis) => ({
        data1: dis.distanceName,
        data2: dis.distanceVal1,
        data3: `${((dis.distanceVal1 / totalDis) * 100).toFixed(2)}`,
        order: dis.orderby,
      }))
      .sort((a, b) => a.order - b.order);

    const pieOptions = tableData.map((dis) => [
      dis.data1,
      parseFloat(dis.data3),
    ]);
    pieOptions.unshift(["Task", "Hours per Day"]);
    disCoveredPeriodInit[0].tabledata = tableData;
    disCoveredPeriodInit[0].tablehead[0].data2 = totalDis;
    disCoveredPeriodInit[0].pieData = pieOptions;
    setDisCoveredPeriod(disCoveredPeriodInit);
  };

  const setTimeDistributionPeriod = (timeDistribution = []) => {
    console.log("timeDistribution.......", timeDistribution)
    const totalDays = timeDistribution.reduce(
      (n, { distanceVal1 }) => n + distanceVal1, 0);
    const tableData = timeDistribution
      .map((date) => ({
        data1: date.distanceName,
        data2: date.distanceVal1,
        data3: `${((date.distanceVal1 / totalDays) * 100).toFixed(2)}`,
        order: date.orderby,
      }))
      .sort((a, b) => a.order - b.order);

    // console.log("tableData......", tableData)
    const pieOptions = tableData.map((days) => [
      days.data1,
      parseFloat(days.data3),
    ]);
    pieOptions.unshift(["Task", "Hours Per Day"]);
    timeDistributionInit[0].tabledata = tableData;
    timeDistributionInit[0].tablehead[0].data2 = totalDays;
    timeDistributionInit[0].pieData = pieOptions;
    setTimeDistribution(timeDistributionInit);
  };

  const setCargoDelivered = (cargoPeriodData = []) => {
    const totalDis = cargoPeriodData.reduce(
      (n, { distanceVal1 }) => n + distanceVal1,
      0
    );
    const tableData = cargoPeriodData.map((date) => ({
      data1: date.distanceName,
      data2: date.distanceVal1,
      data3: `${((date.distanceVal1 / totalDis) * 100).toFixed(2)}`,
    }));
    const pieOptions = cargoPeriodData.map((dis) => [
      dis.distanceName,
      parseFloat(((dis.distanceVal1 / totalDis) * 100).toFixed(2)),
    ]);
    pieOptions.unshift(["Task", "Hours Per Day"]);
    cargoDeliveredPeriodInit[0].tabledata = tableData;
    cargoDeliveredPeriodInit[0].tablehead[0].data2 = totalDis;
    cargoDeliveredPeriodInit[0].pieData = pieOptions;
    setCargoDeliveredPeriod(cargoDeliveredPeriodInit);
  };

  const setDWTUtilization = (speedData = []) => {
    const tableData = speedData.map((date) => ({
      data1: date.distanceName,
      data2: date.distanceVal1,
      data3: date.distanceVal2,
      data4: date.distanceVal3,
      order: date.orderby,
    }))
      .sort((a, b) => a.order - b.order);
    DWTUtilInit[0].tabledata = tableData;
    setSpeedUtilization(DWTUtilInit);
  };

  const getVesselDisCovered = async () => {
    try {
      const data = await apiService.get(
        `${urlService.vesselDistance}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );

      if (data.length) {
        const groupedData = groupDistance(data, "distanceCat");
        setDistanceCovered(groupedData["DistCov"]);
        setTimeDistributionPeriod(groupedData["TimeDist"]);
        setCargoDelivered(groupedData["CargoCov"]);
        setDWTUtilization(groupedData["DWTUtil"]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVesselFuelConsumption = async () => {
    try {
      const data = await apiService.get(
        `${urlService.VesselFOC}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      if (data.length) {
        const formattedData = data.map((fuel) => {
          const total = fuel.hfo + fuel.vlsfo + fuel.ulsfo + fuel.mgo;
          return {
            data1: fuel.machinary,
            data2: fuel.hfo,
            data3: fuel.vlsfo,
            data4: fuel.ulsfo,
            data5: fuel.mgo,
            data6: total,
          };
        });

        const verticalTotalHfo = data.reduce((n, { hfo }) => n + hfo, 0);
        const verticalTotalVlsfo = data.reduce((n, { vlsfo }) => n + vlsfo, 0);
        const verticalTotalUlsfo = data.reduce((n, { ulsfo }) => n + ulsfo, 0);
        const verticalTotalMgo = data.reduce((n, { mgo }) => n + mgo, 0);
        const fullTotal = formattedData.reduce((n, { data6 }) => n + data6, 0);

        fuelConsumptionInit[0].tabledata = formattedData;
        fuelConsumptionInit[0].tablefooter = [
          {
            data1: "Total",
            data2: verticalTotalHfo,
            data3: verticalTotalVlsfo,
            data4: verticalTotalUlsfo,
            data5: verticalTotalMgo,
            data6: fullTotal,
          },
        ];
        setFuelConsumption([...fuelConsumptionInit]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   getVesselDisCovered();
  //   getVesselEmissionPeriod();
  //   getVesselFuelConsumption();
  // }, [fromDate]);

  // useEffect(() => {
  //   getVesselInfo();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch vessel info
        const vesselData = await apiService.get(
          `${urlService.vesselInfo}?VesseID=${vesselId}`
        );
        if (vesselData.length) {
          const dataPoints = vesselData[0];
          setVesselInfo(dataPoints);
          setVesselEffRatingTable([
            {
              title: "Vessel Efficiency Ratings:",
              tablehead: [
                {
                  data1: "Vessel Type",
                  data2: dataPoints.vesselType,
                },
              ],
              tabledata: [
                {
                  data1: "DWT",
                  data2: "[mt]",
                  data3: dataPoints.deadWeight,
                },
                {
                  data1: "EEDI",
                  data2: <GCO2 />,
                  data3: dataPoints.eedi,
                },
                {
                  data1: "EEDI Vref",
                  data2: "[knots]",
                  data3: dataPoints.eediVpsf,
                },
                {
                  data1: "EEXI ",
                  data2: <GCO2 />,
                  data3: dataPoints.eexi,
                },
                {
                  data1: "EEXI Vref",
                  data2: "[knots]",
                  data3: dataPoints.eediVpsf,
                },
              ],
            },
          ]);
        }
  
        // Fetch emissions data
        const emissionsData = await apiService.get(
          `${urlService.emissionsInPeriod}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (emissionsData.length) {
          const formattedData = emissionsData.map((em) => ({
            data1: em.emissionName,
            data2: em.emissionCat,
            data3: roundToDecimals(em.emission, 0),
          }));
          setEmissionPeriodTable([
            {
              title: "Emissions in Period [mt]:",
              tabledata: formattedData,
            },
          ]);
        }
  
        // Fetch distance data
        const distanceData = await apiService.get(
          `${urlService.vesselDistance}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (distanceData.length) {
          const groupedData = groupDistance(distanceData, "distanceCat");
          setDistanceCovered(groupedData["DistCov"]);
          setTimeDistributionPeriod(groupedData["TimeDist"]);
          setCargoDelivered(groupedData["CargoCov"]);
          setDWTUtilization(groupedData["DWTUtil"]);
        }
  
        // Fetch fuel consumption data
        const fuelData = await apiService.get(
          `${urlService.VesselFOC}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (fuelData.length) {
          const formattedFuelData = fuelData.map((fuel) => {
            const total = fuel.hfo + fuel.vlsfo + fuel.ulsfo + fuel.mgo;
            return {
              data1: fuel.machinary,
              data2: fuel.hfo,
              data3: fuel.vlsfo,
              data4: fuel.ulsfo,
              data5: fuel.mgo,
              data6: total,
            };
          });
  
          const verticalTotalHfo = fuelData.reduce((n, { hfo }) => n + hfo, 0);
          const verticalTotalVlsfo = fuelData.reduce((n, { vlsfo }) => n + vlsfo, 0);
          const verticalTotalUlsfo = fuelData.reduce((n, { ulsfo }) => n + ulsfo, 0);
          const verticalTotalMgo = fuelData.reduce((n, { mgo }) => n + mgo, 0);
          const fullTotal = formattedFuelData.reduce((n, { data6 }) => n + data6, 0);
  
          fuelConsumptionInit[0].tabledata = formattedFuelData;
          fuelConsumptionInit[0].tablefooter = [
            {
              data1: "Total",
              data2: verticalTotalHfo,
              data3: verticalTotalVlsfo,
              data4: verticalTotalUlsfo,
              data5: verticalTotalMgo,
              data6: fullTotal,
            },
          ];
          setFuelConsumption([...fuelConsumptionInit]);
        }
  
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [vesselId, fromDate, toDate]);

  const calculateDays = () => {
    let dateRange = additionalData.dateRange;
    if (dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[1]);
      const endDate = new Date(dateRange[0]);

      // Calculate the time difference in milliseconds
      const timeDifference = Math.abs(endDate - startDate);

      // Calculate the number of days and hours
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      // Calculate the fractional days
      const fractionalDays = Math.round((hours / 24) * 100);
      //return `${days}.${fractionalDays}`;
      const timeDifferenceDays = timeDifference / (1000 * 60 * 60 * 24);
      const roundedDays = Math.round(timeDifferenceDays * 10) / 10;
      // timeDifference;
      return `${roundedDays}`;
    }
  };

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };
  const roundToDecimaldays = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };

  return (
    <div style={{backgroundColor:"#FFF"}} ref={printRef} container>
      <Header toogleDrawer={toogleDrawer} />

      <DrawerHeader />
      <div style={{ marginTop: "15px", marginLeft: "15px", marginRight: "15px" }}>
        <Grid style={{}} item className="subheader" container>
          <Grid flexGrow={1}>
            <Typography
              className={`${classes.header} vesselreport`}
              align="left"
            >
              VESSEL EMISSION REPORT{" "}
            </Typography>
            <Typography
              className={`${classes.header} vesselreport`}
              align="left"
              style={{ textTransform: "capitalize" }}
            >
              Vessel : {vesselInfo.vesselName} | {vesselInfo.vesselType}
            </Typography>
            <Typography
              className={`${classes.header} vesselreport`}
              align="left"
            >
              Period : {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
              {moment(toDate).format("DD-MMM-YYYY HH:mm")} |{" "}
              {calculateDays()
                ? roundToDecimaldays(calculateDays(), 1)
                : ""}{" "}
              days
            </Typography>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            
            <Button
              type="button"
              className={classes.button}
              style={{ marginRight: "16px" }}
              onClick={() => redirectoverview()}
            >

              <ArrowBackIcon className={classes.imageIcon} />
              <Typography className="vesseltext" align="left">
                Home
              </Typography>{" "}
              <HomeIcon className={classes.imageIcon} />
            </Button>
            { <Button
              type="button"
              className={classes.button}
              onClick={() => handleCapture()}
            // onClick={() => handleExportWithComponent()}
            >
              <Typography className="vesseltext" align="left">
                Print as PDF
              </Typography>{" "}
              <PrintIcon className={classes.imageIcon} />
            </Button> }
          </Grid>
        </Grid>
      </div>
      <Row style={{ marginTop: "10px", marginLeft: "15px", marginRight: "15px" }}>

        <Col xs="6">
          <Card style={{padding: "9px"}}>  
          <Map style={{ width: "100%" }} id="mapboxContainer" />
          </Card >

         <div id="disCoveredPeriod">
            <DataTableWithPieChart data={disCoveredPeriod} common={1} />
          </div>
          <div style={{ marginTop: "15px" }}>
            <DataTableWithPieChart data={timeDistribution} common={2} />
          </div>
          <SpeedUtilizationDataTable data={speedUtilization} />
          <FuelConsumptionDataTable data={fuelConsumption} />
          <DataTableWithPieChart
            data={cargoDeliveredPeriod}
            common={3}
          />
         

        </Col>
        <Col>
          <EmissionsAndVesselEffDataTable
            data={vesselEffRatingTable}
            datatwo={emissionPeriodTable}
            dynamicName={vesselInfo.vesselType}
          />
           <Card style={{ marginTop: "15px" }}>
            <Co2EmissionChart
              vesselId={vesselId}
              fromDate={fromDate}
              toDate={toDate}
              additionalData={additionalData}
            />
          </Card>
          <Card style={{ marginTop: "15px" }}>
            <VesselPPGraph
              vesselId={vesselId}
              fromDate={fromDate}
              toDate={toDate}
              additionalData={additionalData}
            />
          </Card> 
          <Card style={{ marginTop: "15px" }}>
            <VesselSSCGraph
              vesselId={vesselId}
              fromDate={fromDate}
              toDate={toDate}
              additionalData={additionalData}
            />
          </Card>
          <Card style={{ marginTop: "15px" }}>
            <SpeedInstructionGraph
              vesselId={vesselId}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Card> 

        </Col>
      
      </Row>

    </div>
  );
}
