import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../styles/style.css";
import Oceanpass from "../../assets/Oceanpassreport.png";
import { useNavigate } from "react-router-dom";
import urlService from "../../services/UrlService";
import apiService from "../../services/ApiService";
import { useAuth } from "../../utils/AuthProvider";

const theme = createTheme();

export default function SignInSide(props) {
  const signup = props.signup;
  const navigate = useNavigate();
  const { login } = useAuth();
  const [values, setValues] = React.useState({
    email: "",
    helpertextEmail: "",
    helpertextPassword: "",
    password: "",
    name: "",
    helpertextName: "",
  });

  const handleSubmit = async () => {
    let error = false;
    console.log("ghghhg")
    // Reset helper texts before validation
    setValues(prevValues => ({
      ...prevValues,
      helpertextEmail: "",
      helpertextPassword: "",
    }));

    // Regex for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is empty
    if (!values.email) {
      setValues(prevValues => ({
        ...prevValues,
        helpertextEmail: "Email is required",
      }));
      error = true;
    } else if (!emailPattern.test(values.email)) {
      // Check if email is in the correct format
      setValues(prevValues => ({
        ...prevValues,
        helpertextEmail: "Enter a valid email address",
      }));
      error = true;
    }

    // Check if password is empty
    if (!values.password) {
      setValues(prevValues => ({
        ...prevValues,
        helpertextPassword: "Password is required",
      }));
      error = true;
    }

    // Stop submission if there is any error
    if (error) {
      return;
    }

    const body = {
      email: values.email,
      password: values.password,
    };

    try {
      const apiResp = await apiService.post("Authentication", body);

      // Log the entire response to understand its structure
      console.log("API Response:", apiResp);

      // Check if apiResp exists and whether it's an array or object
      if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
        const { companyName, emailID, userRole, accountName } = apiResp[0];

        // Perform additional validation on the API response if needed
        if (
          companyName === "NA" ||
          emailID === "NA" ||
          userRole === "NA" ||
          accountName === "NA"
        ) {
          return;
        }

        // If login is successful, call the login function and redirect
        login(apiResp[0]);
        navigate("/fleetoverview");
      } else if (apiResp && typeof apiResp === "object") {
        // If the response is an object and not an array, destructure accordingly
        const { companyName, emailID, userRole, accountName } = apiResp;

        // Perform validation on the API response if needed
        if (
          companyName === "NA" ||
          emailID === "NA" ||
          userRole === "NA" ||
          accountName === "NA"
        ) {
          return;
        }

        // If login is successful, call the login function and redirect
        login(apiResp);
        navigate("/fleetoverview");
      } else {
        // Handle case where the response doesn't have the expected structure
      }

    } catch (error) {
      console.error("Login error:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        // Show email/password incorrect error
        setValues(prevValues => ({
          ...prevValues,
          helpertextEmail: "Email and password are incorrect",
          helpertextPassword: "Email and password are incorrect",
        }));
      } else {
        // Handle other errors (e.g., network errors)
        console.error("Unexpected error:", error);
      }
    }
  };







  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      helpertextEmail: "",
      helpertextPassword: "",
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "96vh" }}>
        <CssBaseline />

        <Grid
          item
          md={7}
          lg={7}
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}
          className="center-login"
        >
          <img
            src={Oceanpass}
            width="900"
            height="900"
            alt="A one Page Oceanpass report"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className="center-login">
          <Box sx={{ my: 8, mx: 4 }}>
            <Typography
              className="titleText"
              style={{ color: "#32016e" }}
              textAlign={"left"}
            >
              OCEANPASS
            </Typography>
            <Typography
              className="titleText"
              textAlign={"left"}
              style={{ color: "#32016e" }}
            >
              {signup ? "Sign up" : "Sign in"}
            </Typography>

            <Grid className="signincontainer">
              <Box sx={{ mt: 1 }}>
                {signup && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={values.name}
                    onChange={(e) => handleChange(e)}
                    label="Name"
                    name="Name"
                    autoComplete="Name"
                    autoFocus
                    className="textfeildsign"
                    helperText={values.helpertextName}
                    error={values.helpertextName ? true : false}
                  />
                )}

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={values.email}
                  onChange={(e) => handleChange(e)}
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  className="textfeildsign"
                  helperText={values.helpertextEmail}
                  error={values.helpertextEmail ? true : false}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  onChange={(e) => handleChange(e)}
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  className="textfeildsign"
                  helperText={values.helpertextPassword}
                  error={values.helpertextPassword ? true : false}
                />
                {signup && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    onChange={(e) => handleChange(e)}
                    label="Confirm Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className="textfeildsign"
                    helperText={values.helpertextPassword}
                  />
                )}
                <Box display="flex" justifyContent="flex-end" width="100%">
                  {signup ? (
                    ""
                  ) : (
                    <Link href="#" variant="body2" className="forgot">
                      Forgot it?
                    </Link>
                  )}
                </Box>

                <Button
                  type="submit"
                  className="signinbutton"
                  fullWidth
                  onClick={() => handleSubmit()}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {signup ? "Sign up" : "Sign In"}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}