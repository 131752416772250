import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";


function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([])
  const [maxPoint, setMaxPoint] = useState(0)
  const [minPoint, setMinPoint] = useState(0)

  const PPGraphConfig = {
    chart: {
      backgroundColor: null,
      type: 'line',
      height:260,
      width:750,
      
     
    },
    title: {
      text: `
        <div style="display: flex; align-items: center; gap: 104px; width:728px">
          <span style="font-size: 13px; font-weight: bold;">
            Poseidon Principles for Ship Finance (PP-SF)
          </span>
          <div style="
            color: black;
            font-weight: bold;
            margin-top: 0px;
            font-size: 12px;
            display: inline-block;
            margin-left: -51px;
          ">
            AERe = ${props.additionalData.aerW2W}
          </div>
          <div style="
            color: black;
            font-weight: bold;
            font-size: 12px;
            display: inline-block;
            margin-left: 54px;
          ">
            PP-SF Alignment = 
            <span style="
              color: white;
              background: ${props.additionalData.aerW2W > 0 ? '#ff3300' : '#2cc932'};
              padding: 3px 10px;
              border-radius: 10px;
            ">
              ${props.additionalData.ppa}%
            </span>
          </div>
        </div>
      `,
      align: 'left',
      useHTML: true,
    }
    
,    
// title: {
//   text: `
//     <div style="display: flex; align-items: center; width:732px">
//       <span style="font-size: 13px; font-weight: bold; margin-top:10px ; ">Poseidon Principles for Ship Finance (PP-SF)</span>
//       <span style="font-size: 12px; margin-left: 282px; margin-top:10px">${props.additionalData ? `
        
//         PP-SF Alignment = <span style="color: white; background: ${props.additionalData.aerW2W > 0 ? '#ff3300' : '#2cc932'}; padding: 5px 10px; border-radius: 10px;">
//         ${props.additionalData.ppa || 'N/A'}%</span>` : ''}</span>
//     </div>
//   `,
//   align: 'left',
//   useHTML: true,
//   style: {
//     height: '30px',
//   },
// },
   
    
    
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1, // Set grid line width for xAxis
      gridLineColor: '#cccccc', // Set grid line color for xAxis
     
    },
    yAxis: {
      gridLineWidth: 1, // Set grid line width for yAxis
      gridLineColor: '#cccccc', // Set grid line color for yAxis
      tickAmount: 6, // Ensures there are exactly 6 ticks on the y-axis
      title: {
        text: 'AERe [gCO2e/DWTxnm]'
      },
      labels: {
        formatter: function() {
          return this.value.toFixed(1);
        }
      },
    },
    
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      y: 20,
      
    },
    credits: {
      enabled: false
    },
    series: dataSeries,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800
        },
      }]
    }
  };

  const parseData = (data, key) => {
    return data
      .map(point => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };
  const getVesselPPGraph = async () => {
    try {
      const vesselPPDataPoints = await apiService.get(`${urlService.VesselPPGraph}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`)
      const YxUp = parseData(vesselPPDataPoints, 'yxUp');
      const dataSeriesArray = [
      //   {
      //   name: 'YxUp',
      //   data: YxUp,
      //   color: 'purple',
      //   marker: {
      //     enabled: false
      //   },
      //   showInLegend: false
      // },
       {
        name: 'PP-SF Minimum',
        data: parseData(vesselPPDataPoints, 'ppMin'),
        color: '#AA00FF',
        marker: {
          enabled: false
        }
      }, {
        name: 'PP-SF Striving',
        data: parseData(vesselPPDataPoints, 'ppStriving'),
        color: '#0000FF',
        marker: {
          enabled: false
        }
      },
      {
        name: 'AERe W2W',
        data: parseData(vesselPPDataPoints, 'ciicO2'),
        color: '#4600A0',
        type: 'line',
        marker: {
          enabled: false
        }
      }, 
      // {
      //   name: 'YxLow',
      //   data: parseData(vesselPPDataPoints, 'yxLow'),
      //   color: 'purple',
      //   marker: {
      //     enabled: false
      //   },
      //   showInLegend: false
      // }
    ];

      setDataSeries(dataSeriesArray);
      // setMaxPoint(YxUp[0][1])
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getVesselPPGraph()
  }, [])
  return (
    <div  style={{marginTop:"5px",border: "1px solid rgb(215 213 223)"}} className="charts-container center">
      <>
        <HighchartsReact highcharts={Highcharts} options={PPGraphConfig} />
      </>
    </div>
  );
}

export default Co2EmissionChart;