const CIIREF2019 = [
  2.88,
  2.98,
  3,
  3.01,
  3.04,
  3.1,
  3.08,
  3.07,
  3.07,
  3.07,
  3.09,
  3.1,
  3.11,
  3.11,
  3.13,
  3.17,
  3.19,
  3.19,
  3.2,
  3.23,
  3.27,
  3.28,
  3.27,
  3.26,
  3.28,
  3.3,
  3.31,
  3.27,
  3.26,
  3.27,
  3.27,
  3.29,
  3.31,
  3.34,
  3.37,
  3.37,
  3.33,
  3.32,
  3.32,
  3.36,
  3.35,
  3.34,
  3.34,
  3.35,
  3.38,
  3.39,
  3.4,
  3.39,
  3.41,
  3.44,
  3.45,
  3.48,
  3.47,
  3.45,
  3.43,
  3.42,
  3.42,
  3.43,
  3.45,
  3.46,
  3.48,
  3.5,
  3.52,
  3.54,
  3.56,
  3.58,
  3.6,
  3.62,
  3.64,
  3.66,
  3.68,
  3.7,
  3.72,
  3.74,
  3.75,
  3.77,
  3.79,
  3.81,
  3.82,
  3.84,
  3.86,
  3.87,
  3.9,
  3.92,
  3.94,
  3.96,
  3.97,
  3.98,
  3.98,
  3.99,
  4.01,
  4.03,
  4.05,
  4.07,
  4.09,
  4.09,
  4.06,
  4.05,
  4.09,
  4.07,
  4.06,
  4.04,
  4.04,
  4.05,
  4.06,
  4.08,
  4.08,
  4.08,
  4.09,
  4.1,
  4.12,
  4.12,
  4.12,
  4.08,
  4.04,
  4.01,
  3.99,
  3.96,
  3.93,
  3.9,
  3.85
]
export default CIIREF2019;