import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../styles/style.css";
import { DateRangePicker } from "rsuite";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { json, useNavigate, useLocation } from "react-router-dom";
import apiService from "../services/ApiService";
import urlService from "../services/UrlService";
import AbbreviationBox from "./Client/AbbrevationBox";
import { Typography, TextField, Autocomplete, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { color } from "chart.js/helpers";
import { useAuth } from "../utils/AuthProvider";
import { useReactToPrint } from 'react-to-print';
import Logo from "../assets/logooceanpass.png";
import bgimagepdf from "../assets/Images/OPWave.jpg";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//import bgimagepdf from "../assets/Images/pdffrontpage.png";

// import staticpdflogo from "../assets/Images/staticpdflogo.png";
import logoocean from "../assets/Images/logoocean.png";
import OPMountain from "../assets/Images/OPMountain.jpg";

import print_pdf from "../assets/Images/print_pdf.png";
import staticpdflogo from "../assets/Images/100005.png";
import pdfweblogo from "../assets/Images/pdfweblogo.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@mui/icons-material/Print";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4802a0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableCells = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4802a0",
    color: theme.palette.common.white,
    fontSize: "20px",
    fontWeight: 700,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "18px",
  },
}));

const useStyles = makeStyles((theme) => ({
  midContainer: {
    background: theme.palette.background.body,
  },
  button1: {
    width: "100px",
    borderRadius: "20px !important",
    fontSize: "14px",
    marginLeft: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize !important",
  },
  sidebar: {
    width: "10px",
  },
  fleetover: {
    width: "calc(100% - 200px)",
  },
  boxsized: {
    width: "calc(100%)",
    display: "flex",
    overflowX: "auto",
    background: theme.palette.background.body,
  },
  button: {
    width: "150px",
    borderRadius: "20px !important",
    fontSize: "14px",
    marginTop: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize !important",
  },

  imageIcon: {
    width: "20px",
    height: "20px",
    fill: "#fff",
  },
  widthfull: {
    width: "100%",
  },
  header: {
    fontSize: "1.2em !important",
  },
}));

const StyledTableRows = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const TableLayoutContainer = styled(TableContainer)(() => ({
  height: "100%",
  // overflow: "scroll",
  width: "100%",
  maxHeight: 550,
}));
const TableLayoutContainers = styled(TableContainer)(() => ({
  height: "100%",
  // overflow: "scroll",
  width: "100%",
  // maxHeight: 550,
}));

const StickyTableCell = styled(StyledTableCell)({
  position: "sticky",
  background: "white",
});

const LeftStickyTableCell = styled(StickyTableCell)({
  left: 0,
  boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
  zIndex: 1,
});
const LeftStickyTableCells = styled(StickyTableCell)({
  left: 0,
  boxShadow: "1px 0 2px rgba(0,0,0,0.1)",
  // zIndex: 1,
});

const LeftStickyTableHeaderCell = styled(StickyTableCell)({
  left: 0,
  zIndex: 3,
  boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
});
const LeftStickyTableHeaderCells = styled(StyledTableCells)({
  left: 0,
  // zIndex: 3,
  // boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
  backgroundColor: "#fff"
});

const RightStickyTableCell = styled(StickyTableCell)({
  right: 0,
  boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
  zIndex: 1,
});

const RightStickyTableCells = styled(StickyTableCell)({
  right: 0,
  // boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
  // zIndex: 1,
});

const RightStickyTableHeaderCell = styled(StickyTableCell)({
  right: 0,
  zIndex: 3,
  boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
});
const RightStickyTableHeaderCells = styled(StyledTableCells)({
  right: 0,
  backgroundColor: "#fff"
  // zIndex: 3,
  // boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
});

const FleetOverview = () => {
  const location = useLocation();
  const { userinfo } = useAuth();

  const classes = useStyles();
  const additionalData = location.state?.additionalData?.dateRangeback;
  const getFleetOverview = async () => {
    try {
      const fromDate = moment(dateRange[0]).format("DD-MMM-YYYY HH:mm");
      const toDate = moment(dateRange[1]).format("DD-MMM-YYYY HH:mm");
      const data = await apiService.get(
        `${urlService.fleetOverview}?UserID=${userinfo.accountID}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      setProducts(data);
      setFilteredProducts(data);
      setVesselNames([...new Set(data.map((item) => item.name))]);
      setVesselTypes([...new Set(data.map((item) => item.vesselType))]);
    } catch (error) {
      console.error(error);
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Fleet OverView",
  })
  const handlePrintDirect = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Fleet OverView",
  })
  const navigate = useNavigate();

  const [dateRange, setDateRange] = React.useState(
    additionalData
      ? additionalData
      : [
        moment().startOf("year").toDate(),
        moment().subtract("1", "day").endOf("day").toDate(),
      ]
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(55);

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [vesselNames, setVesselNames] = useState([]);
  const [vesselTypes, setVesselTypes] = useState([]);
  const [selectedVesselNames, setSelectedVesselNames] = useState([]);
  const [selectedVesselType, setSelectedVesselType] = useState(null);
  const [pleetpdf, setpleetpdf] = useState(0);

  //   useEffect(() => {
  //     if (pleetpdf === 1) {
  //         handlePrintDirect();
  //         setpleetpdf(0); // Reset to prevent multiple calls
  //     }
  // }, [pleetpdf]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const abbreviations = [
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}> AER </span>, definition: <span style={{fontSize:"19px"}}> Annual Efficiency Ratio, AER = CO2 (T2W) / (DWT X Distance) </span>},
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>AERe</span>, definition: <span style={{fontSize:"19px",}}>Annual Efficiency Ratio, AER = CO2e (W2W) / (DWT X Distance)</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>CO2e</span>, definition: <span style={{fontSize:"19px"}}>Global Warming Potential of CO2, CH4 and N2O emissions in equivalent CO2 emissions</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>EEDI</span>, definition: <span style={{fontSize:"19px"}}>Energy Efficiency Design Index</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>EEDI Vref</span>, definition: <span style={{fontSize:"19px"}}>EEDI reference speed</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>EEOle</span>, definition: <span style={{fontSize:"19px"}}>Energy Efficiency Operating Indicator, EEOI, CO2 (W2W) / (Cargo on board x Distance)</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>EEXI</span>, definition: <span style={{fontSize:"19px"}}>Energy Efficiency Existing Ship Index</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>EEXI Vref</span>, definition: <span style={{fontSize:"19px"}}>EEXI reference speed</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>PP-SF</span>, definition: <span style={{fontSize:"19px"}}>Poseidon Principles for Ship Finance</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>SCC</span>, definition: <span style={{fontSize:"19px"}}>Sea Cargo Charter</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>T2W</span>, definition: <span style={{fontSize:"19px"}}>Tank to Wake emissions, ie emissions from fuel burnt on board</span> },
    { acronym: <span style={{fontSize:"19px",fontWeight:"700"}}>W2W</span>, definition: <span style={{fontSize:"19px"}}>Well to Wake emissions, ie emissions from fuel burnt on board and upstream fuel production</span> },
  ]

  const notes = [
    "Cargo Delivered in Period is a summary of the amount and type of cargoes delivered in the selected period. If a period ends with cargo on board, the cargo will not be included under Cargo Delivered in Period."
  ];  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateRangeChange = (value) => {
    if (value) {
      setDateRange([value[0], value[1]]);
    }
  };
  // const fromDate = moment(additionalData.dateRange[0]).format(
  //   "DD-MMM-YYYY HH:mm"
  // );
  // const toDate = moment(additionalData.dateRange[1]).format(
  //   "DD-MMM-YYYY HH:mm"
  // );

  const disabledFutureDates = (date) => {
    // Return true for dates that are in the future
    return date && date.valueOf() > Date.now();
  };

  const handleredirect = (id, cii, aerT2W, aerW2W, eeoi, ppa, scc) => {
    const startDate = encodeURIComponent(dateRange[0].toISOString())
    const endDate = encodeURIComponent(dateRange[1].toISOString());
    navigate(`/detailpage/${id}?start=${startDate}&end=${endDate}`, {
      state: {
        additionalData: {
          cii: cii,
          aerT2W: aerT2W,
          aerW2W: aerW2W,
          eeoi: eeoi,
          ppa: ppa,
          scc: scc,
          dateRange: dateRange,
        }, // Replace with your actual data
      },
    });
  };
  //   const redirectoverview = () => {
  //     navigate(-1);
  // };
  const goBack = () => {
    window.location.href = '/fleetoverview';
  };

  useEffect(() => {
    getFleetOverview();
  }, [dateRange]);

  useEffect(() => {
    let filtered = products;
    if (selectedVesselNames.length > 0) {
      filtered = filtered.filter((product) =>
        selectedVesselNames.includes(product.name)
      );
    }
    if (selectedVesselType) {
      filtered = filtered.filter(
        (product) => product.vesselType === selectedVesselType
      );
    }
    setFilteredProducts(filtered);
  }, [selectedVesselNames, selectedVesselType, products]);

  const calculateDays = () => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[1]);
      const endDate = new Date(dateRange[0]);

      // Calculate the time difference in milliseconds
      const timeDifference = Math.abs(endDate - startDate);

      // Calculate the number of days and hours
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );


      // Calculate the fractional days
      const fractionalDays = Math.round((hours / 24) * 100);
      //return `${days}.${fractionalDays}`;
      const timeDifferenceDays = timeDifference / (1000 * 60 * 60 * 24);
      const roundedDays = Math.round(timeDifferenceDays * 10) / 10;
      // timeDifference;
      return `${roundedDays}`;

    }
  };

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };
  const roundToDecimaldays = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };

  const formatNumbers = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handlereset = () => {
    setDateRange([
      moment().startOf("year").toDate(),
      moment().subtract("1", "day").endOf("day").toDate()
    ]);

  };
  // Function Declaration (hoisted)
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');  // Ensure two-digit day
    const month = date.toLocaleString('en-GB', { month: 'short' }); // Abbreviated month (e.g., Jan)
    const year = date.getFullYear();  // Full year (e.g., 2024)
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }); // Time in 24-hour format
    return `${day}-${month}-${year} ${time}`;
  }

  // Now use it
  const formattedDateRange = dateRange && dateRange.length === 2
    ? `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`
    : "";






  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      {/* <button onClick={() => { handlePrint(); setpleetpdf(1); }}>Print</button> */}
      {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '35px', marginRight: '35px' }}> */}





      {pleetpdf == 1 ?
        <>

          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight: "80px", marginTop: "20px" }}>
            <Button
              type="button"
              className={classes.button}
              style={{ marginRight: "16px" }}
              onClick={() => goBack()}
            >
              <ArrowBackIcon className={classes.imageIcon} />
              <Typography className="vesseltext" align="left">
                Home
              </Typography>
              <HomeIcon className={classes.imageIcon} />
            </Button>
            <Button
              type="button"
              className={classes.button}
              onClick={() => {
                handlePrintDirect();
                setpleetpdf(1);
              }}
            // onClick={() => handleExportWithComponent()}
            >
              <Typography className="vesseltext" align="left">
                Print as PDF
              </Typography>{" "}
              <PrintIcon className={classes.imageIcon} />
            </Button>

            {/* <button 
    className={classes.button1} 
    onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }}
  >
    Print
  </button> */}
          </div>

          {/* <img  onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }} style={{ cursor: 'pointer', marginTop: '5px', marginRight: '10px',width:"40px" }} alt="logo" src={print_pdf}  /> */}

          <div id="pdf-content" ref={componentRef} className="pdf-documents">
            <div className="pdf-pages" style={{ pageBreakAfter: 'always', overflow: 'visible' }}>
              {/* Page 1 Content */}

              <Grid
className="widthfull"
id="divToPrint"
style={{
  padding: '30px',
  backgroundImage: `url(${OPMountain})`, // Use the imported image variable
  backgroundSize: 'contain', // Adjust size (cover, contain, etc.)
  backgroundPosition: 'center', // Adjust position (center, top, left, etc.)
  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  height: '1120px',
  position: 'relative', // Ensure children use absolute positioning correctly


               
                }}
              >

                {/* Report Content */}
                <Grid
                 className="widthfull"
                 container
                 direction="row"
                 alignItems="center"
                 sx={{
                   position: 'absolute',
                   bottom: { xs: '240px', md: '236px' }, // Adjusts for different screen sizes
                   right: { xs: '0%', md: '0px' },
                   color: '#fff',
                   backgroundColor: "#fff",
                   padding: "15px",
                   marginTop:"5px"
                 }}
                >
                  <Grid item>
                  <img alt="logo" src={staticpdflogo} style={{ marginRight: '10px', height: '111px', marginLeft: "20px" }} />
                  </Grid>
                  {/* <Grid item>
                    <Typography className="fleetreport" align="left" >
                      <span className="fleetreport" style={{ fontWeight: "600", marginLeft: "20px", }}>FLEET OVERVIEW  REPORT</span>
                    </Typography>
                    <br></br>

                    <Grid item xs={12} alignItems="center">
                      <Typography className="fleetreport" style={{ color: "black", marginLeft: "17px", }}><div style={{ marginTop: "15px" }}>Period : {formattedDateRange} | {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ""} days</div></Typography>
                    </Grid>
                  </Grid> */}
                  <Grid item>
      <Typography align="left" sx={{ color: 'black', mb: 1,mt:0.85, fontSize: '25px',fontWeight: 'bold' }} >
      FLEET OVERVIEW  REPORT
      </Typography><br></br>
      <Typography  align="left" sx={{ color: 'black', mb: 1,mt:0.85, fontSize: '25px',fontWeight: 'bold'}}>
        {/* Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType} */}
       {" "}
      </Typography>
      <Typography  align="left" sx={{ color: 'black', mb: 1,mt:0.85, fontSize: '25px',fontWeight: 'bold' }}>
      <div style={{ marginTop: "15px" }}>Period : {formattedDateRange} | {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ""} days</div>
      </Typography>
    </Grid>
                </Grid>

                {/* "logoocean" Text at Bottom */}
                <Grid
                  style={{

                    color: "white",
                    position: "absolute",
                    top: "18px", // Positions "logoocean" text 20px from the bottom
                    left: "0",
                    right: "0",
                    textAlign: "right", // Centers the text horizontally
                    padding: "10px",
                    // top:"20px"
                  }}
                >
                  <img alt="logo" src={Logo} style={{ marginRight: '20px', width: "220px", marginLeft: "40px" }} />
                </Grid>
              </Grid>

            </div>
            <div className="pdf-pages" style={{ pageBreakAfter: 'always', }}>
              {/* <Grid style={{ padding: "20px" }}> */}
              {/* <div className="pdf-page" style={{ pageBreakAfter: 'always', marginLeft: '5px', marginRight: '10px' }}> */}
              <div style={{ backgroundColor: '#EBEBEB', padding: '5px', marginTop: '0px', marginBottom: '20px' }}>
                <div style={{ backgroundColor: '#EBEBEB', padding: '5px', marginTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography  align="left" style={{ marginLeft: '20px', marginTop: '10px' ,fontSize: '20px', fontWeight: 'bold' }}>
                     FLEET OVERVIEW 
                    <Grid item alignItems="center">
                      <br />
                      <Typography  style={{ marginLeft: '0px' ,fontSize: '20px', fontWeight: 'bold', }}>
                        Period : {formattedDateRange} | {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ''} days
                      </Typography>
                    </Grid>
                  </Typography>

                  <img alt="logo" src={Logo} width="220px" style={{ marginLeft: 'auto', marginRight: "25px" }} />
                </div>
                {/* </div> */}



              </div>




              <TableLayoutContainers component={Paper} className="gridcontainer">
                <Table
                  sx={{ minWidth: 870 }}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <LeftStickyTableHeaderCells
                        align="left"
                        nowrap="nowrap"
                        width="10%"
                        fontSize="10px"
                      >
                        <div style={{ fontSize: "14px" }}>Vessel Name</div>
                        <div style={{ fontSize: "14px" }}><span >( Vessel Type)</span></div>
                      </LeftStickyTableHeaderCells>

                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                        fontSize="10px"

                      >
                        <div style={{ fontSize: "14px" }} align="center">Deadweight</div>
                        <div style={{ fontSize: "14px" }} align="center">[mt]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                        fontSize="10px"
                      >
                        <span style={{ fontSize: "14px" }}>Distance</span>
                        {/* Distance / At Sea - Laden / [nm] */}
                        <div style={{ fontSize: "14px" }} align="center"> Laden</div>
                        <div style={{ fontSize: "14px" }} align="center">[nm]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}>Distance</span> <br />
                        {/* Distance / At Sea - Ballast / [nm] */}
                        <div style={{ fontSize: "14px" }} align="center"> Ballast</div>
                        <div style={{ fontSize: "14px" }} align="center">[nm]</div>
                      </StyledTableCells>
                      {/* <StyledTableCells
                align="center"
                nowrap="nowrap"
                className="headertablefixs"
                fontSize="10px"
              >
               <span style={{ fontSize: "14px"}}>Distance</span>  <br />
                <div style={{ fontSize: "14px"}} align="center">Maneuvring</div>
                <div style={{ fontSize: "14px"}} align="center">[nm]</div>
              </StyledTableCells> */}
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                        fontSize="10px"
                      >
                        <span style={{ fontSize: "14px" }}>Distance</span> <br />
                        {/* Distance / Total / [nm] */}
                        <div style={{ fontSize: "14px" }} align="center">Total</div>
                        <div style={{ fontSize: "14px" }} align="center">[nm]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                        fontSize="10px"
                      >
                        <span style={{ fontSize: "14px" }}>Time</span>   <br />
                        {/* - Time / At Sea / Man. / [%] */}
                        <div style={{ fontSize: "14px" }} align="center"> At Sea</div>
                        <div style={{ fontSize: "14px" }} align="center">[%]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}>Time</span>  <br />
                        {/* - Time / Port/Idle / [%] */}
                        <div style={{ fontSize: "14px" }} align="center"> Port / Idle</div>
                        <div style={{ fontSize: "14px" }} align="center">[%]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        {/* CO<span style={{ fontSize: "14px" }}>2</span> Emission <br /> */}
                        <span style={{ fontSize: "14px" }}> CO</span><span style={{ fontSize: "14px" }}>2</span> <br />
                        {/* - CO2 Emission / Tank-2-Wake / [mt] */}
                        <div style={{ fontSize: "14px" }} align="center">T2W</div>
                        <div style={{ fontSize: "14px" }} align="center">[mt]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}> CO</span><span style={{ fontSize: "14px" }}>2e</span> <br />
                        {/* CO2e Emission / Well-2-Wake / [mt] */}
                        <div style={{ fontSize: "14px" }} align="center">W2W</div>
                        <div style={{ fontSize: "14px" }} align="center">[mt]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className=""
                      >
                        <span style={{ fontSize: "14px" }}> AER </span>
                        <br />
                        {/* AER / [gCO2/DWTxnm] */}
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        width="3%"
                        className="headertablefixs"
                      >

                        <span style={{ fontSize: "14px" }}> AERe </span>
                        <br />
                        {/* AERe / [gCO2e/DWTxnm] */}
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}> EEOIe </span>
                        <br />
                        {/* EEOI / [gCO2e/mtxnm] */}
                      </StyledTableCells>
                      <RightStickyTableHeaderCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >

                        <span style={{ fontSize: "14px" }}> CII Rating </span>

                        <br />
                      </RightStickyTableHeaderCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}> PP – SF </span>
                        <br />
                        {/* PP – Ship Finance / Alignment Score / [%] */}
                        <div style={{ fontSize: "14px" }} align="center">Alignment </div>
                        <div style={{ fontSize: "14px" }} align="center">[%]</div>
                      </StyledTableCells>
                      <StyledTableCells
                        align="center"
                        nowrap="nowrap"
                        className="headertablefixs"
                      >
                        <span style={{ fontSize: "14px" }}> SCC </span>
                        <br />
                        {/* SCC / Alignment Score / [%] */}
                        <div style={{ fontSize: "14px" }} align="center">Alignment </div>
                        <div style={{ fontSize: "14px" }} align="center">[%]</div>
                      </StyledTableCells>
                    </TableRow>
                  </TableHead>
                  {/* </Table> */}
                  {/* <div style={{ overflow: 'auto', height: '400px' }}> */}
                  {/* <Table style={{ tableLayout: 'fixed' }}> */}

                  <TableBody style={{ backgroundColor: "#fff" }}>
                    {filteredProducts
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRows
                          style={{ cursor: "pointer" }}
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "10px 16px",
                          }}
                        >
                          <LeftStickyTableCells
                            align="left"
                            style={{
                              padding: "5px 16px",
                              minWidth: "185px",
                              color: "#000",
                              fontSize: "16px", fontWeight: 600
                            }}
                            onClick={() =>
                              handleredirect(
                                row.vesselID,
                                row.cii,
                                row.aerT2W,
                                row.aerW2W,
                                row.eeoi,
                                row.ppa,
                                row.seaCargo
                              )
                            }
                          >
                            <div style={{ fontSize: "15px", fontWeight: 600 }}>{row.name}</div>
                            <div style={{ fontSize: "12px", color: "#808080", }}><span >({row.vesselType})</span></div>
                          </LeftStickyTableCells>

                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {formatNumbers(row.maxDeadWg)}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.distLaden &&
                              formatNumbers(roundToDecimals(row.distLaden, 0))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.distBallest &&
                              formatNumbers(roundToDecimals(row.distBallest, 0))}
                          </StyledTableCells>
                          {/* <StyledTableCells
                    align="right"
                    className="dataTablefix"
                    style={{ padding: "10px 30px" ,fontSize: "15px" ,fontWeight:600 }}
                  >
                    {row.distManeuvring &&
                      formatNumbers(roundToDecimals(row.distManeuvring, 0))}
                  </StyledTableCells> */}

                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.totDistance &&
                              formatNumbers(roundToDecimals(row.totDistance, 0))}
                          </StyledTableCells>

                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.timeSea && formatNumbers(roundToDecimals(row.timeSea, 0))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.timeIdlePort && formatNumbers(roundToDecimals(row.timeIdlePort, 0))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.t2W && formatNumbers(roundToDecimals(row.t2W, 0))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.w2W && formatNumbers(roundToDecimals(row.w2W, 0))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.aerT2W &&
                              formatNumbers(roundToDecimals(row.aerT2W, 2))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.aerW2W &&
                              formatNumbers(roundToDecimals(row.aerW2W, 2))}
                          </StyledTableCells>
                          <StyledTableCells
                            align="right"
                            className="dataTablefix"
                            style={{ padding: "10px 30px", fontSize: "15px", fontWeight: 600 }}
                          >
                            {row.eeoi && formatNumbers(roundToDecimals(row.eeoi, 2))}
                          </StyledTableCells>
                          <RightStickyTableCells
                            align="center"
                            fontWeight="bolder"
                            style={{
                              padding: "10px 15px",
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#fff"
                            }}
                            className="lastindexval"
                          >
                            <span
                              style={{
                                color:
                                  row.cii == "B"
                                    ? "#000"
                                    : row.cii == "A"
                                      ? "#fff"
                                      : row.cii == "C"
                                        ? "#000"
                                        : row.cii == "E"
                                          ? "#fff"
                                          : "#000",
                                background:
                                  row.cii == "B"
                                    ? "#6bde76"
                                    : row.cii == "A"
                                      ? "Green"
                                      : row.cii == "C"
                                        ? "#fefe01"
                                        : row.cii == "E"
                                          ? "red"
                                          : "#f8ba05",
                                padding: "11px 28px", // Adjust padding as needed to fit the text background
                                borderRadius: "15px",
                                fontWeight: 600,
                              }}
                            >
                              {row.cii}
                            </span>
                          </RightStickyTableCells>

                          <StyledTableCells
                            align="center"
                            style={{
                              padding: "10px 16px",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                            className="lastindexval"
                          >
                            <span
                              style={{
                                background: row.ppa > 0 ? "#ff3300" : "Green",
                                color: row.ppa > 0 ? "#fff" : "#fff",

                                padding: "10px 15px",
                                width: "66px",
                                display: "inline-block",
                                textAlign: "center",
                                borderRadius: "15px",
                              }}
                            >
                              {row.ppa && roundToDecimals(row.ppa, 0)}%
                            </span>
                          </StyledTableCells>
                          {/* {row.seaCargo} */}
                          <StyledTableCells
                            align="center"
                            style={{
                              padding: "10px 16px",
                              fontWeight: "bolder",
                              fontSize: "15px",
                            }}
                            className="lastindexval"
                          >
                            <span
                              style={{
                                background: row.seaCargo > 0 ? "#ff3300" : "Green",
                                color: row.seaCargo > 0 ? "#fff" : "#fff",

                                padding: "10px 15px",
                                width: "66px",  // Set fixed width for uniformity
                                display: "inline-block",
                                textAlign: "center",
                                borderRadius: "15px",
                              }}
                            >

                              {row.seaCargo && `${roundToDecimals(row.seaCargo, 0)}%`}
                            </span>
                          </StyledTableCells>

                        </StyledTableRows>
                      ))}
                  </TableBody>
                </Table>
                {/* </div> */}
              </TableLayoutContainers><br></br>
              {/* <div style={{marginTop:"200px"}} className="page-footer">
    <Typography variant="body1" style={{ textAlign: 'center',  }}>
      Page {1} of {2}
    </Typography>
  </div> */}

            </div>

            <div className="pdf-pages" style={{ pageBreakAfter: 'always', overflow: 'visible' }}>
              <div style={{ backgroundColor: '#EBEBEB', padding: '5px', marginTop: '0px', marginBottom: '20px' }}>
                <div style={{ backgroundColor: '#EBEBEB', padding: '5px', marginTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography  align="left" style={{ marginLeft: '20px', marginTop: '10px', fontSize: '20px', fontWeight: 'bold' }}>
                    FLEET OVERVIEW
                    <Grid item alignItems="center">
                      <br />
                      <Typography style={{ marginLeft: '0px',fontSize: '20px', fontWeight: 'bold' }}>
                        Period : {formattedDateRange} | {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ''} days
                      </Typography>
                    </Grid>
                  </Typography>

                  <img alt="logo" src={Logo} width="220px" style={{ marginLeft: 'auto', marginRight: "25px" }} />
                </div>

              </div>
              {/* Page 1 Content */}
              <div style={{ textAlign: 'left', padding: '20px 30px', marginTop: '30px' }}>
                <span style={{ fontSize: "24px", fontWeight: "700" }}>Abbreviations :</span>
                <div><br></br></div>
                <div>
                  {abbreviations.map((item, index) => (
                    <div key={index} style={{ marginBottom: '5px', fontSize: "34x" }}>
                      <strong>{item.acronym}:</strong> {item.definition}
                    </div>
                  ))}
                </div>
                <div><br></br></div>
                <span style={{ fontSize: "24px", fontWeight: "700" }}>Notes :</span>

                <div>
                  {notes.map((note, index) => (
                    <div key={index} style={{ marginBottom: '5px', fontSize: "18px" }}>{note}</div>
                  ))}
                </div>
              </div>
              {/* <div  style={{marginTop:"510px"}} className="page-footer">
    <Typography variant="body2" style={{ textAlign: 'center', marginTop: '10px' }}>
      Page {2} of {2}
    </Typography>
  </div> */}



            </div></div>
        </>
        :
        <Grid style={{ padding: "20px" }}>
          <Grid display="flex" alignItems="center" justifyContent="space-between" className="headertext">
            <Typography variant="h5" align="left">
              FLEET OVERVIEW
            </Typography>
            {/* <button 
    className={classes.button1} 
    onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }}
  >
    Print
  </button> */}
            <Button
              type="button"
              className={classes.button}
              onClick={() => {
                handlePrint();
                setpleetpdf(1);
              }}
            // onClick={() => handleExportWithComponent()}
            >
              <Typography className="vesseltext" align="left">
                Print as PDF
              </Typography>{" "}
              <PrintIcon className={classes.imageIcon} />
            </Button>

            {/* <img  onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }} style={{ cursor: 'pointer', marginTop: '5px', marginRight: '10px',width:"40px" }} alt="logo" src={print_pdf}  /> */}
            {/* <FontAwesomeIcon 
    icon={faPrint} 
    onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }}
   
  /> */}
          </Grid>
          <Grid container style={{ paddingBottom: "20px" }} alignItems={"center"}>
            <Grid item container xs={6} alignItems={"center"}>
              <Typography className="headertext2" align="left">
                Period:{" "}
              </Typography>
              <DateRangePicker
                placeholder="dd-MMM-yyyy HH:mm to dd-MMM-yyyy HH:mm"
                value={dateRange}
                className="datepick"
                width="350px"
                onClean={handlereset}
                onChange={handleDateRangeChange}
                disabledDate={disabledFutureDates}
                format="dd-MMM-yyyy HH:mm"
              />
              <Typography style={{ paddingLeft: "10px" }}>
                {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ""} days
              </Typography>
            </Grid>
            <Grid item container xs={6}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Typography className="headertext2" align="left">
                    Filter:{" "}
                  </Typography>
                  <Autocomplete
                    style={{ paddingRight: "10px" }}
                    options={vesselTypes}
                    value={selectedVesselType}
                    onChange={(event, newValue) => setSelectedVesselType(newValue)}
                    margin="dense"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }} // Always show the label on top
                        label="Vessel Type"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        sx={{
                          width: 200,
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    options={vesselNames}
                    value={selectedVesselNames}
                    onChange={(event, newValue) => setSelectedVesselNames(newValue)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ fontSize: "16px" }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vessel Name"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        InputLabelProps={{ shrink: true }} // Always show the label on top
                        sx={{
                          minWidth: 200,
                          maxWidth: 200,
                          fontSize: "16px", // Custom font size
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.length > 0 ? <></> : null
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Targeting the input field box
                        maxHeight: "85px", // Set the maximum height
                        overflowY: "auto", // Enable vertical scroll if needed
                      },
                      "& .MuiAutocomplete-tag": {
                        // Targeting the individual tags within the input
                        fontSize: "16px", // Custom font size for tags
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <TableLayoutContainer component={Paper} className="gridcontainer">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <LeftStickyTableHeaderCell
                    align="left"
                    nowrap="nowrap"

                    fontSize="10px"
                  >
                    <div style={{ fontSize: "14px" }}>Vessel Name</div>
                    <div style={{ fontSize: "12px" }}><span >( Vessel Type)</span></div>
                  </LeftStickyTableHeaderCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    <div align="center">Deadweight</div>
                    <div align="center">[mt]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Distance
                    {/* Distance / At Sea - Laden / [nm] */}
                    <div align="center">At Sea - Laden</div>
                    <div align="center">[nm]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Distance <br />
                    {/* Distance / At Sea - Ballast / [nm] */}
                    <div align="center">At Sea - Ballast</div>
                    <div align="center">[nm]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Distance <br />
                    {/* Distance / Maneuvring / [nm] */}
                    <div align="center">Maneuvring</div>
                    <div align="center">[nm]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Distance <br />
                    {/* Distance / Total / [nm] */}
                    <div align="center">Total</div>
                    <div align="center">[nm]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Time <br />
                    {/* - Time / At Sea / Man. / [%] */}
                    <div align="center"> At Sea</div>
                    <div align="center">[%]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    Time <br />
                    {/* - Time / Port/Idle / [%] */}
                    <div align="center"> Port / Idle</div>
                    <div align="center">[%]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    CO<span style={{ fontSize: "12px" }}>2</span> Emission <br />
                    {/* - CO2 Emission / Tank-2-Wake / [mt] */}
                    <div align="center">Tank-2-Wake</div>
                    <div align="center">[mt]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    CO<span style={{ fontSize: "12px" }}>2e</span> Emission <br />
                    {/* CO2e Emission / Well-2-Wake / [mt] */}
                    <div align="center">Well-2-Wake</div>
                    <div align="center">[mt]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    AER
                    <br />
                    {/* AER / [gCO2/DWTxnm] */}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    AERe <br />
                    {/* AERe / [gCO2e/DWTxnm] */}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    EEOIe <br />
                    {/* EEOI / [gCO2e/mtxnm] */}
                  </StyledTableCell>
                  <RightStickyTableHeaderCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    CII Rating <br />
                  </RightStickyTableHeaderCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    PP – SF <br />
                    {/* PP – Ship Finance / Alignment Score / [%] */}
                    <div align="center">Alignment </div>
                    <div align="center">[%]</div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    nowrap="nowrap"
                    className="headertablefix"
                  >
                    SCC <br />
                    {/* SCC / Alignment Score / [%] */}
                    <div align="center">Alignment </div>
                    <div align="center">[%]</div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {/* </Table> */}
              {/* <div style={{ overflow: 'auto', height: '400px' }}> */}
              {/* <Table style={{ tableLayout: 'fixed' }}> */}

              <TableBody>
                {filteredProducts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      style={{ cursor: "pointer" }}
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "10px 16px",
                      }}
                    >
                      <LeftStickyTableCell
                        align="left"
                        style={{
                          padding: "5px 16px",
                          minWidth: "184px",
                          color: "navey"
                          
                        }}
                        onClick={() =>
                          handleredirect(
                            row.vesselID,
                            row.cii,
                            row.aerT2W,
                            row.aerW2W,
                            row.eeoi,
                            row.ppa,
                            row.seaCargo
                          )
                        }
                      >
                        <div style={{ fontSize: "15px", fontWeight: "bold" }}>{row.name}</div>
                        <div style={{ fontSize: "12px", color: "#808080", }}><span >({row.vesselType})</span></div>
                      </LeftStickyTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px", fontSize: "16px", fontWeight: "bold", }}
                      >
                        {formatNumbers(row.maxDeadWg)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.distLaden &&
                          formatNumbers(roundToDecimals(row.distLaden, 0))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px" ,fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.distBallest &&
                          formatNumbers(roundToDecimals(row.distBallest, 0))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px" ,fontSize: "16px", fontWeight: "bold",}}
                      >
                        {row.distManeuvring &&
                          formatNumbers(roundToDecimals(row.distManeuvring, 0))}
                      </StyledTableCell>

                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.totDistance &&
                          formatNumbers(roundToDecimals(row.totDistance, 0))}
                      </StyledTableCell>

                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.timeSea}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.timeIdlePort}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.t2W && formatNumbers(roundToDecimals(row.t2W, 0))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.w2W && formatNumbers(roundToDecimals(row.w2W, 0))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.aerT2W &&
                          formatNumbers(roundToDecimals(row.aerT2W, 2))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.aerW2W &&
                          formatNumbers(roundToDecimals(row.aerW2W, 2))}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="dataTablefix"
                        style={{ padding: "10px 30px",fontSize: "16px", fontWeight: "bold", }}
                      >
                        {row.eeoi && formatNumbers(roundToDecimals(row.eeoi, 2))}
                      </StyledTableCell>
                      <RightStickyTableCell
                        align="center"
                        fontWeight="bolder"
                        style={{
                          padding: "10px 15px",
                          fontWeight: "bold",
                          fontSize: "15px",
                          color: "#fff"
                        }}
                        className="lastindexval"
                      >
                        <span
                          style={{
                            color:
                              row.cii == "B"
                                ? "#000"
                                : row.cii == "A"
                                  ? "#fff"
                                  : row.cii == "C"
                                    ? "#000"
                                    : row.cii == "E"
                                      ? "#fff"
                                      : "#000",
                            background:
                              row.cii == "B"
                                ? "#6bde76"
                                : row.cii == "A"
                                  ? "Green"
                                  : row.cii == "C"
                                    ? "#fefe01"
                                    : row.cii == "E"
                                      ? "red"
                                      : "#f8ba05",
                            padding: "8px 20px", // Adjust padding as needed to fit the text background
                            borderRadius: "15px",
                            fontWeight: 600,
                          }}
                        >
                          {row.cii}
                        </span>
                      </RightStickyTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          backgroundColor:"white",
                          padding: "10px 16px",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        className="lastindexval"
                      >
                        <span
                          style={{
                            background: row.ppa > 0 ? "#ff3300" : "Green",
                            color: row.ppa > 0 ? "#fff" : "#fff",

                            padding: "6px 0px",
                            width: "50px",
                            display: "inline-block",
                            textAlign: "center",
                            borderRadius: "15px",
                          }}
                        >
                          {row.ppa && roundToDecimals(row.ppa, 0)}%
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          backgroundColor:"white",
                          padding: "10px 16px",
                          fontWeight: "bolder",
                          fontSize: "15px",
                        }}
                        className="lastindexval"
                      >
                        <span
                          style={{
                            background: row.seaCargo > 0 ? "#ff3300" : "Green",
                            color: row.seaCargo > 0 ? "#fff" : "#fff",

                            padding: "6px 0px",
                            width: "50px",  // Set fixed width for uniformity
                            display: "inline-block",
                            textAlign: "center",
                            borderRadius: "15px",
                          }}
                        >

                          {row.seaCargo && `${roundToDecimals(row.seaCargo, 0)}%`}
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            {/* </div> */}
          </TableLayoutContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AbbreviationBox />
        </Grid>}

    </>

  );
};

export default FleetOverview;
