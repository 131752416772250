import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useEffect, useState } from "react";
import apiService from "../../services/ApiService";
import urlService from "../../services/UrlService";
import co2Line from "../Constants/Co2Line";
import { Grid, Tooltip, Typography } from "material-ui";
import { fontString } from "chart.js/helpers";
import { min } from "date-fns";
import { zIndex } from "material-ui/styles";

function Co2EmissionChart(props) {
  const { fromDate, toDate, vesselId } = props;
  const [dataSeries, setDataSeries] = useState([]);
  const [datamax, setMax] = useState(0);
  const [datamin, setMin] = useState(0);
  const [range, setRange] = useState({});

  const additionalData = props.additionalData || {};

  const co2EmissionChartConfig = {
    chart: {
      backgroundColor: "white",
      type: "area",
      height:255,
      width:750,
    },
    title: {
      text: `
        <div style="display: flex; align-items: center; justify-content: space-between; width: 727px;">
          <div style="
            font-size: 13px;
            height: 30px;
            
            margin-right: 10px;
            display: inline-block;
            flex: 1; /* Allows equal space for each section */
            text-align: left; /* Aligns text to the left */
          ">
            IMO – Carbon Intensity Indicator (CII)
          </div>
          <div style="
            color: black;
            font-weight: 600;
            font-size: 12px;
           margin-top:-10px;
            display: inline-block;
            flex: 1;
            text-align: center; /* Centers the text in the middle section */
          ">
            AER = ${additionalData.aerT2W || 'N/A'} 
          </div>
          <div style="
            color: black;
            font-weight: bold;
            font-size: 12px;
            display: inline-block;
            margin-top:-10px;
            flex: 1;
            text-align: right; /* Aligns text to the right */
           
          ">
            CII Rating : <span style="
              background: ${
                additionalData.cii === "B" ? "#98cd51"
              : additionalData.cii === "A" ? "Green"
              : additionalData.cii === "C" ? "#ffff0a"
              : additionalData.cii === "D" ? "#ffbe00"
              : additionalData.cii === "E" ? "red" : "#f8ba05"
              };
              color: ${
                additionalData.cii === "B"
                ? "#000"
                : additionalData.cii === "A"
                ? "#fff"
                : additionalData.cii === "C"
                ? "#000"
                : additionalData.cii === "E" ? "#fff" : "#000"
              };
              padding: 7px 10px;
              border-radius: 10px;
              font-size: 10px;
            ">${additionalData.cii || 'N/A'}</span>
          </div>
        </div>
      `,
      align: 'left',
      useHTML: true,
    },
    
    
    legend: false,
    // legend: {
    //   enabled: true, // Enable the legend
    //   layout: 'horizontal', // You can also use 'vertical'
    //   align: 'center', // Align horizontally ('left', 'center', 'right')
    //   verticalAlign: 'bottom', // Align vertically ('top', 'middle', 'bottom')
    //   itemStyle: {
    //     fontSize: '12px', // Adjust font size
    //     fontWeight: 'normal', // Adjust font weight
    //   },
    //   symbolHeight: 12, // Adjust symbol size in the legend
    //   symbolWidth: 12,
    //   symbolRadius: 3, // Roundness for the symbols
    // },
    credits: {
      enabled: false,
    },

    yAxis: {     
  max: datamax ,
  min: datamin ,
      startOnTick: false,
      // tickAmount: 7,
      endOnTick: false,
      tickInterval: 0.5, // Adjust this value based on your data and desired interval
      tickPixelInterval: 40, // Adjust as needed to manage spacing between ticks
      title: {
        useHTML: true,
        text: "AER [gCO2/DWTxnm]",
        style: {
          fontSize: "10px",
         
        }
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(1); // Adjust to 2 decimal places or as needed
        },},
      plotBands: [
        {
          from: range.yxLow,
          to: range.ciiab,
          color: "Green",
          zIndex: 2,
        },
        {
          from: range.ciiab,
          to: range.ciibc,
          color: "#98cd51",
          zIndex: 2,
        },
        {
          from: range.ciibc,
          to: range.ciicd,
          color: "#ffff0a",
          zIndex: 2,
        },
        {
          from: range.ciicd,
          to: range.ciide,
          color: "#ffbe00",
          zIndex: 2,
        },
        {
          from: range.ciide,
          to: range.yxUp,
          color: "#fe0200",
          zIndex: 2,
        },
      ],
      tickColor: "#999", // Color for tick marks
      tickWidth: 2, // Width of tick marks
      lineColor: "#999", // Color of the axis line
      // plotLines: [{ // Optional: add reference lines if needed
      //   // color: '#FF0000', // Color of the reference line
      //   width: 2, // Width of the reference line
      //   value: 5, // Value for the reference line
      //   zIndex: 5, // Ensure it appears on top
      // }],
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "",
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const date = moment(this.x).format('YYYY-MM-DD'); // Format the date
        return `
          <b>${this.series.name}</b><br/>
          Date: ${date}<br/>
          Value: ${this.y}
        `;
      },
    },
    plotOptions: {
      area: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
      },
    },
    series: dataSeries,
  };
  
  
  
  
  
  

  const parseData = (data, key) => {
    return data
      .map((point) => [Date.parse(point.yDate), point[key]])
      .sort((a, b) => a[0] - b[0]);
  };

  const getCo2EmissionChart = async () => {
    try {
      const Co2EmissionDataPoints = await apiService.get(
        `${urlService.co2EmissionChart}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      const dataSeriesArray = [
        {
          name: "ciIref",
          color: "#000",
          type: "line",
          dashStyle: "LongDash",
          data: parseData(Co2EmissionDataPoints, "ciIref"),
        },
        {
          name: "AER T2W",
          color: "#4600A0",
          type: "line",
          data: parseData(Co2EmissionDataPoints, "ciicO2"),
        },
      ];

      const Co2EmissionDataPointIndex =
        Co2EmissionDataPoints &&
        Co2EmissionDataPoints.length > 0 &&
        Co2EmissionDataPoints[0];
      setDataSeries(dataSeriesArray);
      setRange(Co2EmissionDataPointIndex);

      setMax(Co2EmissionDataPointIndex.yxUp);
      setMin(Co2EmissionDataPointIndex.yxLow);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCo2EmissionChart();
  }, [fromDate, toDate, vesselId]); // Add dependencies if needed

  return (
    <div className="charts-container center" style={{ marginTop:"5px" , paddingBottom: "2px",border: "1px solid rgb(215 213 223)",}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={co2EmissionChartConfig}
      />
<div style={{ display: "flex", justifyContent: "center",  }}>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
    <div
      style={{
        backgroundColor: "green",
        width: "12px",
        height: "12px",
        display: "inline-block",
        marginRight: "6px",
      }}
    ></div>
    CII A
  </div>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
    <div
      style={{
        backgroundColor: "#98cd51",
        width: "12px",
        height: "12px",
        display: "inline-block",
        marginRight: "6px",
      }}
    ></div>
    CII B
  </div>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
    <div
      style={{
        backgroundColor: "#ffff0a",
        width: "12px",
        height: "12px",
        display: "inline-block",
        marginRight: "6px",
      }}
    ></div>
    CII C
  </div>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
    <div
      style={{
        backgroundColor: "#ffbe00",
        width: "12px",
        height: "12px",
        display: "inline-block",
        marginRight: "6px",
      }}
    ></div>
    CII D
  </div>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
    <div
      style={{
        backgroundColor: "#fe0200",
        width: "12px",
        height: "12px",
        display: "inline-block",
        marginRight: "6px",
      }}
    ></div>
    CII E
  </div>
  <div style={{ paddingRight: "20px", fontSize: "12px" }}>
  <div
    style={{
      backgroundColor: "#000",
      width: "20px",
      height: "2px",
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "6px",
    }}
  ></div>
  CII Ref Line
</div>
<div style={{ paddingRight: "20px", fontSize: "12px" }}>
  <div
    style={{
      backgroundColor: "#4600A0",
      width: "20px",
      height: "2px",
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "6px",
    }}
  ></div>
  AER T2W
</div>
</div>






    </div>
  );
}

export default Co2EmissionChart;
