import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userinfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  // Set user info from localStorage on component mount
  useEffect(() => {
    const storedLoginInfo = localStorage.getItem("loginInfo");
    if (storedLoginInfo) {
      setUserInfo(JSON.parse(storedLoginInfo));
    }
    setLoading(false); // Done loading after checking localStorage
  }, []);

  const login = (loginInfo) => {
    localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
    setUserInfo(loginInfo);
  };

  const logout = () => {
    localStorage.removeItem('loginInfo');
    setUserInfo(null);
  };

  const isAuthenticated = !!userinfo;

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userinfo, loading }}>
      {!loading && children} {/* Render children only after loading is complete */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
