const CIIREF2019Dates = [
  "01/01/24 12:00",
  "02/01/24 12:00",
  "03/01/24 12:00",
  "04/01/24 12:00",
  "05/01/24 12:00",
  "06/01/24 12:00",
  "07/01/24 12:00",
  "08/01/24 12:00",
  "09/01/24 9:00",
  "09/01/24 12:00",
  "10/01/24 12:00",
  "11/01/24 12:00",
  "11/01/24 17:00",
  "11/01/24 21:06",
  "12/01/24 12:00",
  "13/01/24 12:00",
  "14/01/24 6:36",
  "14/01/24 12:00",
  "14/01/24 21:24",
  "15/01/24 12:00",
  "16/01/24 12:00",
  "16/01/24 18:12",
  "17/01/24 0:42",
  "17/01/24 12:00",
  "18/01/24 12:00",
  "19/01/24 5:00",
  "19/01/24 12:00",
  "20/01/24 7:18",
  "20/01/24 12:00",
  "20/01/24 14:30",
  "20/01/24 15:18",
  "21/01/24 12:00",
  "22/01/24 12:00",
  "23/01/24 12:00",
  "24/01/24 11:30",
  "24/01/24 12:00",
  "24/01/24 15:48",
  "25/01/24 12:00",
  "25/01/24 15:15",
  "26/01/24 12:00",
  "27/01/24 12:00",
  "28/01/24 1:24",
  "28/01/24 2:54",
  "28/01/24 12:00",
  "29/01/24 12:00",
  "30/01/24 8:18",
  "30/01/24 12:00",
  "30/01/24 19:12",
  "31/01/24 12:00",
  "01/02/24 12:00",
  "03/02/24 12:00",
  "04/02/24 7:50",
  "04/02/24 12:00",
  "05/02/24 12:00",
  "05/02/24 22:30",
  "06/02/24 4:00",
  "06/02/24 4:42",
  "06/02/24 12:00",
  "07/02/24 12:00",
  "08/02/24 12:00",
  "09/02/24 12:00",
  "10/02/24 12:00",
  "11/02/24 12:00",
  "12/02/24 12:00",
  "13/02/24 12:00",
  "14/02/24 12:00",
  "15/02/24 12:00",
  "16/02/24 12:00",
  "17/02/24 12:00",
  "18/02/24 12:00",
  "19/02/24 12:00",
  "20/02/24 12:00",
  "21/02/24 12:00",
  "22/02/24 12:00",
  "23/02/24 12:00",
  "24/02/24 12:00",
  "26/02/24 12:00",
  "27/02/24 12:00",
  "28/02/24 12:00",
  "29/02/24 12:00",
  "01/03/24 12:00",
  "02/03/24 12:00",
  "03/03/24 12:00",
  "04/03/24 12:00",
  "05/03/24 12:00",
  "06/03/24 12:00",
  "07/03/24 12:00",
  "07/03/24 14:30",
  "07/03/24 15:42",
  "08/03/24 12:00",
  "09/03/24 12:00",
  "10/03/24 12:00",
  "11/03/24 12:00",
  "12/03/24 12:00",
  "13/03/24 12:00",
  "13/03/24 12:36",
  "13/03/24 16:00",
  "14/03/24 12:00",
  "15/03/24 12:00",
  "16/03/24 12:00",
  "17/03/24 12:00",
  "18/03/24 12:00",
  "18/03/24 14:36",
  "19/03/24 12:00",
  "20/03/24 12:00",
  "21/03/24 12:00",
  "22/03/24 1:06",
  "22/03/24 4:12",
  "22/03/24 12:00",
  "23/03/24 12:00",
  "24/03/24 12:00",
  "24/03/24 16:06",
  "24/03/24 17:06",
  "25/03/24 12:00",
  "26/03/24 12:00",
  "27/03/24 12:00",
  "28/03/24 12:00",
  "29/03/24 12:00",
  "30/03/24 12:00",
  "31/03/24 12:00",
  "01/04/24 14:00"
].map((date) => {
  const split = date.split('/');
  const split1 = split[2].split(' ')
  return new Date(split[1] + '/' + split[0] + '/' + split1[0])
});
export default CIIREF2019Dates;