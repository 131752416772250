import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Switch, FormControlLabel, FormControl, InputLabel, Select, Box, OutlinedInput, Chip } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  AccountName: Yup.string()
    .required('Account Name is required'),
  EmailID: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  Role: Yup.string()
    .oneOf(['Admin', 'User'], 'Select a valid Role')
    .required('Role is required'),
  IsActive: Yup.boolean()
    .required('IsActive is required'),
  Vessels: Yup.array()
    .min(1, 'Select at least one vessel')
    .required('Vessels are required')
});

const AddUserFormDialog = ({ open, handleClose, onSubmit, userInfo }) => {
  const [vesselOptions, setVesselOptions] = useState(['Vessel 1', 'Vessel 2', 'Vessel 3']); // Example vessel options

  const formik = useFormik({
    initialValues: {
      AccountName: '',
      EmailID: '',
      Role: '',
      IsActive: false,
      Vessels: []
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      onSubmit(values); // Close dialog after submission
    },
  });

  useEffect(() => {
    if (userInfo) {
      formik.setValues({
        AccountName: userInfo?.AccountName,
        EmailID: userInfo?.EmailID,
        Role: userInfo?.Role,
        IsActive: userInfo?.IsActive,
        Vessels: userInfo?.Vessels || [],
      });
    } else {
      formik.resetForm()
    }
  }, [userInfo])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            id="AccountName"
            name="AccountName"
            label="Account Name"
            value={formik.values.AccountName}
            onChange={formik.handleChange}
            error={formik.touched.AccountName && Boolean(formik.errors.AccountName)}
            helperText={formik.touched.AccountName && formik.errors.AccountName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="EmailID"
            name="EmailID"
            label="Email ID"
            value={formik.values.EmailID}
            onChange={formik.handleChange}
            error={formik.touched.EmailID && Boolean(formik.errors.EmailID)}
            helperText={formik.touched.EmailID && formik.errors.EmailID}
            margin="normal"
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="Role"
              name="Role"
              value={formik.values.Role}
              onChange={formik.handleChange}
              label="Role"
              error={formik.touched.Role && Boolean(formik.errors.Role)}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
            </Select>
            {formik.touched.Role && formik.errors.Role && (
              <div style={{ color: 'red', fontSize: '0.75rem' }}>{formik.errors.Role}</div>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="Vessels-label">Vessels</InputLabel>
            <Select
              labelId="Vessels-label"
              id="Vessels"
              name="Vessels"
              multiple
              value={formik.values.Vessels}
              onChange={formik.handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Vessels" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              error={formik.touched.Vessels && Boolean(formik.errors.Vessels)}
            >
              {vesselOptions.map((vessel) => (
                <MenuItem key={vessel} value={vessel}>
                  {vessel}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.Vessels && formik.errors.Vessels && (
              <div style={{ color: 'red', fontSize: '0.75rem' }}>{formik.errors.Vessels}</div>
            )}
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                id="IsActive"
                name="IsActive"
                checked={formik.values.IsActive}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="Active"
          />
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserFormDialog;
