import React, { useEffect, useState,useCallback,useRef } from "react";

import { Grid, Typography, Divider } from "@mui/material";
import DataTableWithPieChart2 from "../components/Datatables/DataTableWithPieChart2";
import SpeedUtilizationDataTable2 from "../components/Datatables/SpeedUtilizationDataTable2";
import FuelConsumptionDataTable1 from "../components/Datatables/FuelConsumptionDataTable1";
import EmissionsAndVesselEffDataTable2 from "../components/Datatables/EmissionsAndVesselEffDataTable2";
import Map2 from "../components/Charts/Map2";
import Logo from "../assets/logooceanpass.png";
import bgimagepdf from "../assets/Images/OPWave.jpg";
//import bgimagepdf from "../assets/Images/pdffrontpage.png";

// import staticpdflogo from "../assets/Images/staticpdflogo.png";
import logoocean from "../assets/Images/logoocean.png";

import staticpdflogo from "../assets/Images/100005.png";
import pdfweblogo from "../assets/Images/pdfweblogo.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { PDFExport } from "@progress/kendo-react-pdf";
import html2canvas from "html2canvas";
import print_pdf from "../assets/Images/print_pdf.png";

import "../styles/style.css";
// import html2pdf from 'html2pdf.js';
import { makeStyles } from "@material-ui/core/styles";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { useState } from "react";
import apiService from "../services/ApiService";
import urlService from "../services/UrlService";
import moment from "moment";
// import { useEffect } from "react";
import Co2EmissionChart2 from "../components/Charts/Co2EmissionGraph2";
import VesselSSCGraph2 from "../components/Charts/VesselSSCGraph2";
import VesselPPGraph2 from "../components/Charts/VesselPPGraph2";
import SpeedInstructionGraph2 from "../components/Charts/SpeedInstructionGraph2";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
// import { Button, Form, FormGroup, Label, Input,Card } from "reactstrap";
// import htmlToImage from 'html-to-image';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from "@mui/icons-material/Print";

var htmlToImage = require('html-to-image');
const abbreviations = [
  { acronym: 'AER', definition: 'Annual Efficiency Ratio, AER = CO2 (T2W) / (DWT X Distance)' },
  { acronym: 'AERe', definition: 'Annual Efficiency Ratio, AER = CO2e (W2W) / (DWT X Distance)' },
  { acronym: 'CO2e', definition: 'Global Warming Potential of CO2, CH4 and N2O emissions in equivalent CO2 emissions' },
  { acronym: 'EEDI', definition: 'Energy Efficiency Design Index' },
  { acronym: 'EEDI Vref', definition: 'EEDI reference speed' },
  { acronym: 'EEOle', definition: 'Energy Efficiency Operating Indicator, EEOI, CO2 (W2W) / (Cargo on board x Distance)' },
  { acronym: 'EEXI', definition: 'Energy Efficiency Existing Ship Index' },
  { acronym: 'EEXI Vref', definition: 'EEXI reference speed' },
  { acronym: 'PP-SF', definition: 'Poseidon Principles for Ship Finance' },
  { acronym: 'SCC', definition: 'Sea Cargo Charter' },
  { acronym: 'T2W', definition: 'Tank to Wake emissions, ie emissions from fuel burnt on board' },
  { acronym: 'W2W', definition: 'Well to Wake emissions, ie emissions from fuel burnt on board and upstream fuel production' },
];

const notes = [
  "Cargo Delivered in Period is a summary of the amount and type of cargoes delivered in the selected period. If a period ends with cargo on board, the cargo will not be included under Cargo Delivered in Period."
]; 
const useStyles = makeStyles((theme) => ({
  midContainer: {
    background: theme.palette.background.body,
  },
  button1: {
    width: "100px",
    borderRadius: "20px !important",
    fontSize: "14px",
    marginLeft: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize !important",
  },
  sidebar: {
    width: "10px",
  },
  fleetover: {
    width: "calc(100% - 200px)",
  },
  boxsized: {
    width: "calc(100%)",
    display: "flex",
    overflowX: "auto",
    background: theme.palette.background.body,
  },
  button: {
    width: "150px",
    borderRadius: "20px !important",
    fontSize: "14px",
    marginTop: "18px",
    marginRight: "8px",
    height: "33px",
    background: "#4802a0 !important",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize !important",
  },

  imageIcon: {
    width: "20px",
    height: "20px",
    fill: "#fff",
  },
  widthfull: {
    width: "100%",
  },
  header: {
    fontSize: "1.2em !important",
  },
}));


const groupDistance = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default function Print(props) {
  const classes = useStyles();

  const disCoveredPeriodInit = [
    {
      title: "Distance Covered in Period [nautical miles]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        toolbar: "false",
        pieSliceText: "none",
        align: "center",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const timeDistributionInit = [
    {
      title: "Time Distribution in Period [Days]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        pieSliceText: "none",
        align: "center",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const DWTUtilInit = [
    {
      title: "Average Speed at Sea and DWT Utilization:",
      tablehead: [
        {
          data1: "",
          data2: "Speed Over Ground",
          data3: "Instructed Speed",
          data4: "DWT Utilization",
        },
        {
          data1: "",
          data2: "[kn]",
          data3: "[kn]",
          data4: "[% of max DWT]",
        },
      ],
      tabledata: [],
    },
  ];
  
  const cargoDeliveredPeriodInit = [
    {
      title: "Cargo Delivered in Period [mt]:",
      tablehead: [{ data1: "Total", data2: "0", data3: "100%" }],
      tabledata: [],
      pieData: [["Task", "Hours per Day"]],
      pieOptions: {
        backgroundColor: "transparent",
        sliceVisibilityThreshold: 0,
        legend: "true",
        pieSliceText: "none",
        pieHole: 0.5,
        is3D: false,
        colors: ["#4600a0", "#7d00ff", "#aa00ff", "#d7afff"],
        chartArea: { left: 20, top: 20, right: 0, bottom: 20 },
      },
    },
  ];
  
  const fuelConsumptionInit = [
    {
      title: "Energy Consumption [mt]:",
      tablehead: [
        {
          data1: "",
          data2: "HSHFO",
          data3: "VLSFO",
          data4: "ULSFO",
          data5: "MGO/MDO",
          data6: "Total",
        },
      ],
      tabledata: [
        {
          data1: "Main engine",
          data2: "0",
          data3: "4,945",
          data4: "0",
          data5: "324",
          data6: "5,269",
        },
        {
          data1: "Aux. engine",
          data2: "0",
          data3: "4,96",
          data4: "0",
          data5: "146",
          data6: "642",
        },
        {
          data1: "Aux. bailer",
          data2: "0",
          data3: "79",
          data4: "0",
          data5: "47",
          data6: "127",
        },
        {
          data1: "Various",
          data2: "0",
          data3: "0",
          data4: "0",
          data5: "0",
          data6: "0",
        },
      ],
      tablefooter: [
        {
          data1: "Total",
          data2: "0",
          data3: "0",
          data4: "0",
          data5: "0",
          data6: "0",
        },
      ],
    },
  ];
  
  const vesselEffRatingInit = [
    {
      title: "Vessel Efficiency Ratings:",
      tablehead: [
        {
          data1: "Vessel Type",
          data2: "",
        },
      ],
      tabledata: [
        {
          data1: "Deadweight (mt)",
          data2: "0",
        },
        {
          data1: `EEDI (gCO<span style={{ fontSize: "11px" }}>2</span> /mtxnm)`,
          data2: "0",
        },
        {
          data1: "EEDI Vpsf(knots)",
          data2: "0",
        },
        {
          data1: "EEXI (gCO2/mtxnm)",
          data2: "0",
        },
        {
          data1: "EEXI Vref(knots)",
          data2: "0",
        },
      ],
    },
  ];
  
  const emissionPeriodInitialData = [
    {
      title: "Emissions in period [mt]:",
      tabledata: [
        {
          data1: "",
          data2: "",
          data3: "",
        },
      ],
    },
  ];
  
  const printRef = React.useRef();
  const navigate = useNavigate();
  const { vesselId } = useParams();
  const [searchParams] = useSearchParams();
  const [vesselInfo, setVesselInfo] = useState({
    imoNo: "",
    vesselName: "",
    vesselType: "",
    deadWeight: 0,
    rightRate: "",
    eedi: 0,
    eediVpsf: 0,
    eexi: 0,
    eexiVref: 0,
  });
  const location = useLocation();

  const additionalData = location.state?.additionalData;

  const [emissionPeriodTable, setEmissionPeriodTable] = useState(
    emissionPeriodInitialData
  );
  const [vesselEffRatingTable, setVesselEffRatingTable] =
    useState(vesselEffRatingInit);
    console.log(vesselEffRatingTable,"vesselEffRatingInit",vesselEffRatingInit)
  const [disCoveredPeriod, setDisCoveredPeriod] =
    useState(disCoveredPeriodInit);
    console.log("disCoveredPeriodInit",disCoveredPeriodInit)

  const [timeDistribution, setTimeDistribution] =
    useState(timeDistributionInit);
  const [cargoDeliveredPeriod, setCargoDeliveredPeriod] = useState(
    cargoDeliveredPeriodInit
  );
  const [fuelConsumption, setFuelConsumption] = useState(fuelConsumptionInit);
  const [speedUtilization, setSpeedUtilization] = useState(DWTUtilInit);

  const fromDate = moment(additionalData.dateRange[0]).format(
    "DD-MMM-YYYY HH:mm"
  );
  const toDate = moment(additionalData.dateRange[1]).format(
    "DD-MMM-YYYY HH:mm"
  );
  const componentRef = useRef();
 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: vesselInfo.vesselName + " " +"VESSEL EMISSION REPORT",
  })
//   useEffect(() => {
//     handlePrint();  // Call the print function initially

//     // Set a timer to navigate back after 15 seconds
//     const timer = setTimeout(() => {
//         navigate(-1); // Navigate back
//     }, 1002); // 15000 milliseconds = 15 seconds

//     // Cleanup the timer on unmount
//     return () => clearTimeout(timer);
// }, []);
  // useEffect(() => {
  //   setTimeout(() => {
     
  //     handlePrint();
  //   }, 100);
  //  }, [handlePrint]); // Ensure handlePrint is called only once when the component mounts

  const groupDistance = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const [state, setState] = useState({
    isOpen: false,
  });

  const toogleDrawer = (e) => {
    setState({ isOpen: !state.isOpen });
  };

  const handleCapture = () => {

    navigate(`/print/${vesselId}?start=${fromDate}&end=${toDate}`, {
      state: {
        additionalData: additionalData // Replace with your actual data
      },
    });

    // navigate(`/print/${vesselId}?start=${fromDate}&end=${toDate}`);
  };

  const redirectoverview = () => {
    navigate(`/fleetoverview`, {
      state: {
        additionalData: { dateRangeback: additionalData.dateRange }, // Replace with your actual data
      },
    });
  };
  const GCO2 = () => (
    <>
      [gCO<span style={{ fontSize: "11px" }}>2</span> /mtxnm]
    </>
  );

  const getVesselInfo = async () => {
    try {
      const data = await apiService.get(
        `${urlService.vesselInfo}?VesseID=${vesselId}`
      );
      if (data.length) {
        const dataPoints = data[0];
        setVesselInfo(dataPoints);
        setVesselEffRatingTable([
          {
            title: "Vessel Efficiency Ratings:",
            tablehead: [
              {
                data1: "Vessel Type",
                data2: dataPoints.vesselType,
              },
            ],
            tabledata: [
              {
                data1: "DWT",
                data2: "[mt]",
                data3: dataPoints.deadWeight,
              },
              {
                data1: "EEDI",
                data2: <GCO2 />,
                data3: dataPoints.eedi,
              },
              {
                data1: "EEDI Vref",
                data2: "[knots]",
                data3: dataPoints.eediVpsf,
              },
              {
                data1: "EEXI ",
                data2: <GCO2 />,
                data3: dataPoints.eexi,
              },
              {
                data1: "EEXI Vref",
                data2: "[knots]",
                data3: dataPoints.eediVpsf,
              },
            ],
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVesselEmissionPeriod = async () => {
    try {
      const data = await apiService.get(
        `${urlService.emissionsInPeriod}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      if (data.length) {
        const formattedData = data.map((em) => ({
          data1: em.emissionName,
          data2: em.emissionCat,
          data3: roundToDecimals(em.emission, 0),
        }));
        setEmissionPeriodTable([
          {
            title: "Emissions in Period [mt]:",
            tabledata: formattedData,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setDistanceCovered = (distanceCovered = []) => {
    const totalDis = distanceCovered.reduce(
      (n, { distanceVal1 }) => n + distanceVal1,
      0
    );
    const tableData = distanceCovered
      .map((dis) => ({
        data1: dis.distanceName,
        data2: dis.distanceVal1,
        data3: `${((dis.distanceVal1 / totalDis) * 100).toFixed(2)}`,
        order: dis.orderby,
      }))
      .sort((a, b) => a.order - b.order);

    const pieOptions = tableData.map((dis) => [
      dis.data1,
      parseFloat(dis.data3),
    ]);
    pieOptions.unshift(["Task", "Hours per Day"]);
    disCoveredPeriodInit[0].tabledata = tableData;
    disCoveredPeriodInit[0].tablehead[0].data2 = totalDis;
    disCoveredPeriodInit[0].pieData = pieOptions;
    setDisCoveredPeriod(disCoveredPeriodInit);
  };

  const setTimeDistributionPeriod = (timeDistribution = []) => {
    console.log("timeDistribution.......", timeDistribution)
    const totalDays = timeDistribution.reduce(
      (n, { distanceVal1 }) => n + distanceVal1,0);
    const tableData = timeDistribution
      .map((date) => ({
        data1: date.distanceName,
        data2: date.distanceVal1,
        data3: `${((date.distanceVal1 / totalDays) * 100).toFixed(2)}`,
        order: date.orderby,
      }))
      .sort((a, b) => a.order - b.order);

    // console.log("tableData......", tableData)
    const pieOptions = tableData.map((days) => [
      days.data1,
      parseFloat(days.data3),
    ]);
    pieOptions.unshift(["Task", "Hours Per Day"]);
    timeDistributionInit[0].tabledata = tableData;
    timeDistributionInit[0].tablehead[0].data2 = totalDays;
    timeDistributionInit[0].pieData = pieOptions;
    setTimeDistribution(timeDistributionInit);
  };

  const setCargoDelivered = (cargoPeriodData = []) => {
    const totalDis = cargoPeriodData.reduce(
      (n, { distanceVal1 }) => n + distanceVal1,
      0
    );
    const tableData = cargoPeriodData.map((date) => ({
      data1: date.distanceName,
      data2: date.distanceVal1,
      data3: `${((date.distanceVal1 / totalDis) * 100).toFixed(2)}`,
    }));
    const pieOptions = cargoPeriodData.map((dis) => [
      dis.distanceName,
      parseFloat(((dis.distanceVal1 / totalDis) * 100).toFixed(2)),
    ]);
    pieOptions.unshift(["Task", "Hours Per Day"]);
    cargoDeliveredPeriodInit[0].tabledata = tableData;
    cargoDeliveredPeriodInit[0].tablehead[0].data2 = totalDis;
    cargoDeliveredPeriodInit[0].pieData = pieOptions;
    setCargoDeliveredPeriod(cargoDeliveredPeriodInit);
  };

  const setDWTUtilization = (speedData = []) => {
    const tableData = speedData.map((date) => ({
      data1: date.distanceName,
      data2: date.distanceVal1,
      data3: date.distanceVal2,
      data4: date.distanceVal3,
      order: date.orderby,
    }))
    .sort((a, b) => a.order - b.order);
    DWTUtilInit[0].tabledata = tableData;
    setSpeedUtilization(DWTUtilInit);
  };
  const goBack = () => {
    window.history.back();
  };

  const getVesselDisCovered = async () => {
    try {
      const data = await apiService.get(
        `${urlService.vesselDistance}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );

      if (data.length) {
        const groupedData = groupDistance(data, "distanceCat");
        setDistanceCovered(groupedData["DistCov"]);
        setTimeDistributionPeriod(groupedData["TimeDist"]);
        setCargoDelivered(groupedData["CargoCov"]);
        setDWTUtilization(groupedData["DWTUtil"]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVesselFuelConsumption = async () => {
    try {
      const data = await apiService.get(
        `${urlService.VesselFOC}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
      );
      if (data.length) {
        const formattedData = data.map((fuel) => {
          const total = fuel.hfo + fuel.vlsfo + fuel.ulsfo + fuel.mgo;
          return {
            data1: fuel.machinary,
            data2: fuel.hfo,
            data3: fuel.vlsfo,
            data4: fuel.ulsfo,
            data5: fuel.mgo,
            data6: total,
          };
        });

        const verticalTotalHfo = data.reduce((n, { hfo }) => n + hfo, 0);
        const verticalTotalVlsfo = data.reduce((n, { vlsfo }) => n + vlsfo, 0);
        const verticalTotalUlsfo = data.reduce((n, { ulsfo }) => n + ulsfo, 0);
        const verticalTotalMgo = data.reduce((n, { mgo }) => n + mgo, 0);
        const fullTotal = formattedData.reduce((n, { data6 }) => n + data6, 0);

        fuelConsumptionInit[0].tabledata = formattedData;
        fuelConsumptionInit[0].tablefooter = [
          {
            data1: "Total",
            data2: verticalTotalHfo,
            data3: verticalTotalVlsfo,
            data4: verticalTotalUlsfo,
            data5: verticalTotalMgo,
            data6: fullTotal,
          },
        ];
        setFuelConsumption([...fuelConsumptionInit]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchVesselData = useCallback((fromDate) => {
    const timer = setTimeout(async () => {
      try {
        await Promise.all([
          getVesselInfo(),
          getVesselEmissionPeriod(),
          getVesselDisCovered(),
          getVesselFuelConsumption()
        ]);
      } catch (error) {
        console.error("Error fetching vessel data:", error);
      }
    }, 100); // Adjust the debounce time as needed
  
    return () => clearTimeout(timer);
  }, [fromDate]);
  useEffect(() => {
    if (fromDate) {
      fetchVesselData(fromDate);
    }
  }, [fromDate]);
 
 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch vessel info
        const vesselData = await apiService.get(
          `${urlService.vesselInfo}?VesseID=${vesselId}`
        );
        if (vesselData.length) {
          const dataPoints = vesselData[0];
          setVesselInfo(dataPoints);
          setVesselEffRatingTable([
            {
              title: "Vessel Efficiency Ratings:",
              tablehead: [
                {
                  data1: "Vessel Type",
                  data2: dataPoints.vesselType,
                },
              ],
              tabledata: [
                {
                  data1: "DWT",
                  data2: "[mt]",
                  data3: dataPoints.deadWeight,
                },
                {
                  data1: "EEDI",
                  data2: <GCO2 />,
                  data3: dataPoints.eedi,
                },
                {
                  data1: "EEDI Vref",
                  data2: "[knots]",
                  data3: dataPoints.eediVpsf,
                },
                {
                  data1: "EEXI ",
                  data2: <GCO2 />,
                  data3: dataPoints.eexi,
                },
                {
                  data1: "EEXI Vref",
                  data2: "[knots]",
                  data3: dataPoints.eediVpsf,
                },
              ],
            },
          ]);
        }
  
        // Fetch emissions data
        const emissionsData = await apiService.get(
          `${urlService.emissionsInPeriod}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (emissionsData.length) {
          const formattedData = emissionsData.map((em) => ({
            data1: em.emissionName,
            data2: em.emissionCat,
            data3: roundToDecimals(em.emission, 0),
          }));
          setEmissionPeriodTable([
            {
              title: "Emissions in Period [mt]:",
              tabledata: formattedData,
            },
          ]);
        }
  
        // Fetch distance data
        const distanceData = await apiService.get(
          `${urlService.vesselDistance}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (distanceData.length) {
          const groupedData = groupDistance(distanceData, "distanceCat");
          setDistanceCovered(groupedData["DistCov"]);
          setTimeDistributionPeriod(groupedData["TimeDist"]);
          setCargoDelivered(groupedData["CargoCov"]);
          setDWTUtilization(groupedData["DWTUtil"]);
        }
  
        // Fetch fuel consumption data
        const fuelData = await apiService.get(
          `${urlService.VesselFOC}?VesseID=${vesselId}&dtStart=${fromDate}&DtEnd=${toDate}`
        );
        if (fuelData.length) {
          const formattedFuelData = fuelData.map((fuel) => {
            const total = fuel.hfo + fuel.vlsfo + fuel.ulsfo + fuel.mgo;
            return {
              data1: fuel.machinary,
              data2: fuel.hfo,
              data3: fuel.vlsfo,
              data4: fuel.ulsfo,
              data5: fuel.mgo,
              data6: total,
            };
          });
  
          const verticalTotalHfo = fuelData.reduce((n, { hfo }) => n + hfo, 0);
          const verticalTotalVlsfo = fuelData.reduce((n, { vlsfo }) => n + vlsfo, 0);
          const verticalTotalUlsfo = fuelData.reduce((n, { ulsfo }) => n + ulsfo, 0);
          const verticalTotalMgo = fuelData.reduce((n, { mgo }) => n + mgo, 0);
          const fullTotal = formattedFuelData.reduce((n, { data6 }) => n + data6, 0);
  
          fuelConsumptionInit[0].tabledata = formattedFuelData;
          fuelConsumptionInit[0].tablefooter = [
            {
              data1: "Total",
              data2: verticalTotalHfo,
              data3: verticalTotalVlsfo,
              data4: verticalTotalUlsfo,
              data5: verticalTotalMgo,
              data6: fullTotal,
            },
          ];
          setFuelConsumption([...fuelConsumptionInit]);
        }
  
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [vesselId, fromDate, toDate]);
 

  const calculateDays = () => {
    let dateRange = additionalData.dateRange;
    if (dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[1]);
      const endDate = new Date(dateRange[0]);

      // Calculate the time difference in milliseconds
      const timeDifference = Math.abs(endDate - startDate);

      // Calculate the number of days and hours
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      // Calculate the fractional days
      const fractionalDays = Math.round((hours / 24) * 100);
      //return `${days}.${fractionalDays}`;
      const timeDifferenceDays = timeDifference / (1000 * 60 * 60 * 24);
      const roundedDays = Math.round(timeDifferenceDays * 10) / 10;
      // timeDifference;
      return `${roundedDays}`;
    }
  };

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };
  const roundToDecimaldays = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(number * factor) / factor).toFixed(decimals);
  };

  return (
    <>
     {/* <img  onClick={() => {
      handlePrint(); 
    }} style={{ cursor: 'pointer', marginTop: '5px', marginRight: '10px',width:"40px" }} alt="logo" src={print_pdf}  /> */}
   
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center",marginRight:"80px",marginTop:"20px" }}>
  <Button
    type="button"
    className={classes.button}
    style={{ marginRight: "16px" }}
    onClick={() => goBack()}
  >
    <ArrowBackIcon className={classes.imageIcon} />
    <Typography className="vesseltext" align="left">
      Home
    </Typography>
    <HomeIcon className={classes.imageIcon} />
  </Button>
  <Button
              type="button"
              className={classes.button}
              onClick={() => {
                handlePrint(); 
              }}
            // onClick={() => handleExportWithComponent()}
            >
              <Typography className="vesseltext" align="left">
                Print as PDF
              </Typography>{" "}
              <PrintIcon className={classes.imageIcon} />
            </Button>

  {/* <button 
    className={classes.button1} 
    onClick={() => {
      handlePrint(); 
      setpleetpdf(1);
    }}
  >
    Print
  </button> */}
</div>
      <div id="pdf-content" ref={componentRef} className="pdf-document">
      <div className="pdf-page"  style={{ pageBreakAfter: 'always', overflow: 'visible' }}>
  {/* Page 1 Content */}
  <Grid
  className="widthfull"
  id="divToPrint"
  style={{
    padding: '30px',
    backgroundImage: `url(${bgimagepdf})`, // Use the imported image variable
    backgroundSize: 'contain', // Adjust size (cover, contain, etc.)
    backgroundPosition: 'center', // Adjust position (center, top, left, etc.)
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    height: '1130px',
    position: 'relative', // Ensure children use absolute positioning correctly
  }}
>
  
  <Grid
    className="widthfull"
    container
    direction="row"
    alignItems="center"
    sx={{
      position: 'absolute',
      top: { xs: '240px', md: '236px' }, // Adjusts for different screen sizes
      right: { xs: '0%', md: '-132px' },
      color: '#fff',
      backgroundColor: "#fff",
      padding: "10px",
      marginTop:"5px"
    }}
  >
    <Grid item>
      <img alt="logo" src={staticpdflogo} style={{ marginRight: '10px', height: '101px', marginLeft: "20px" }} />
    </Grid>
    <Grid item>
      <Typography className="vesselreport" align="left" sx={{ color: 'white', mb: 1,mt:0.85}}>
        VESSEL EMISSION REPORT
      </Typography>
      <Typography className="vesselreport" align="left" sx={{ color: 'white', mb: 1,mt:0.85}}>
        Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType}
      </Typography>
      <Typography className="vesselreport" align="left" sx={{ color: 'white', mb: 1,mt:0.85 }}>
        Period: {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
        {moment(toDate).format("DD-MMM-YYYY HH:mm")} |{" "}
        {calculateDays() ? roundToDecimaldays(calculateDays(), 1) : ""} days
      </Typography>
    </Grid>
  </Grid>

  {/* "logoocean" Text at Bottom */}
  <Grid
    style={{
     
      color: "white",
      position: "absolute",
      bottom: "60px", // Positions "logoocean" text 20px from the bottom
      left: "0",
      right: "0",
      textAlign: "left", // Centers the text horizontally
      padding: "10px",
      // top:"20px"
    }}
  >
     <img alt="logo" src={logoocean} style={{ marginRight: '30px', height: '50px', marginLeft: "55px" }} />
  </Grid>
</Grid>

</div>

        <div className="pdf-page" style={{ pageBreakAfter: 'always' }}>
          {/* Page 1 Content */}
          <Grid className="widthfull" id="divToPrint" style={{ padding: '5px 20px 0px 20px' }}>
            
            <Grid style={{ backgroundColor: '#EBEBEB', padding: '5px' }} container>
              <Grid style={{fontSize:"12px"}} flexGrow={1}>
                <Typography className="vesselreport" align="left">
                  <span style={{fontSize:"12px"}}>VESSEL EMISSION REPORT</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}> Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType}</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px" ,}}>Period : {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
                  {moment(toDate).format("DD-MMM-YYYY HH:mm")}   <span style={{marginLeft:"10px" }} > | {" "}
                  {calculateDays()
                    ? roundToDecimaldays(calculateDays(), 1)
                    : ""}{" "}
                    days
                    </span></span>
                </Typography>
              </Grid>
              <Grid display="flex" alignItems="center">
                <img alt="logo" src={Logo} width="130px" style={{ paddingRight: '10px' }} />
              </Grid>
            </Grid>

            <Divider sx={{ borderBottomWidth: '2px', marginBottom: '-2px' }} color="grey" />

            {/* Data table and charts */}
            <EmissionsAndVesselEffDataTable2  data={vesselEffRatingTable} datatwo={emissionPeriodTable}  dynamicName={vesselInfo.vesselType}/>
            <Co2EmissionChart2   vesselId={vesselId}
                      fromDate={fromDate}
                      toDate={toDate}
                      additionalData={additionalData} />
            <VesselPPGraph2 vesselId={vesselId} fromDate={fromDate} toDate={toDate}  additionalData={additionalData}/>
            <VesselSSCGraph2 vesselId={vesselId} fromDate={fromDate} toDate={toDate}  additionalData={additionalData}/>
          </Grid>
          <div className="pdf-footer" style={{ fontSize: "12px", textAlign: "right", marginRight: "20px",marginBottom:"-39px" }}>
  Page 1 of 4
</div> 

         
        </div>
        <div className="pdf-page" style={{ pageBreakAfter: 'always' }}>
          {/* Page 2 Content */}
          <Grid className="widthfull" id="divToPrint" style={{ padding: '10px 20px 0px 20px' }}>
            <Grid style={{ backgroundColor: '#EBEBEB', padding: '5px' }} container>
              <Grid style={{fontSize:"8px"}} flexGrow={1}>
              <Typography className="vesselreport" align="left">
                  <span style={{fontSize:"12px"}}>VESSEL EMISSION REPORT</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}> Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType}</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}>Period : {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
                  {moment(toDate).format("DD-MMM-YYYY HH:mm")} <span style={{marginLeft:"10px" }} > | {" "}
                  {calculateDays()
                    ? roundToDecimaldays(calculateDays(), 1)
                    : ""}{" "}
                    days
                    </span></span>
                </Typography>
              </Grid>
              <Grid display="flex" alignItems="center">
                <img alt="logo" src={Logo} width="130px" style={{ paddingRight: '10px' }} />
              </Grid>
            </Grid>

            <Divider sx={{ borderBottomWidth: '2px', marginBottom: '10px 10px 10px 10px' }} color="grey" />

            {/* Page 2 content */}
            <Map2 style={{ width: '100%' }} id="mapboxContainer" />
            <DataTableWithPieChart2 data={disCoveredPeriod} common={1}/>
            <DataTableWithPieChart2 data={timeDistribution} common={2}/>
            <SpeedUtilizationDataTable2 data={speedUtilization} />


            {/* <SpeedUtilizationDataTable data={speedUtilization} /> */}
            {/* <FuelConsumptionDataTable data={fuelConsumption} /> */}
          </Grid>
          

          
          <div className="pdf-footer2 " style={{ fontSize: "12px", textAlign: "right", marginRight: "20px" }}>
  Page 2 of 4
</div> 
        </div>
        {/* <Grid className="pdf-footer" > */}
          {/* </Grid> */}
        
        <div className="pdf-page" style={{ pageBreakAfter: 'always' }}>
          {/* Page 2 Content */}
          <Grid className="widthfull" id="divToPrint" style={{ padding: '10px 20px 0px 20px' }}>
            <Grid style={{ backgroundColor: '#EBEBEB', padding: '5px' }} container>
              <Grid flexGrow={1}>
              <Typography className="vesselreport" align="left">
                  <span style={{fontSize:"12px"}}>VESSEL EMISSION REPORT</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}> Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType}</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}>Period : {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
                  {moment(toDate).format("DD-MMM-YYYY HH:mm")} <span style={{marginLeft:"10px" }} > | {" "}
                  {calculateDays()
                    ? roundToDecimaldays(calculateDays(), 1)
                    : ""}{" "}
                    days</span></span>
                </Typography>
              </Grid>
              <Grid display="flex" alignItems="center">
                <img alt="logo" src={Logo} width="130px" style={{ paddingRight: '10px' }} />
              </Grid>
            </Grid>

            <Divider sx={{ borderBottomWidth: '2px', marginBottom: '0px 10px 10px 10px' }} color="grey" />

            {/* Page 2 content */}
            <FuelConsumptionDataTable1 data={fuelConsumption} />

            <DataTableWithPieChart2 data={cargoDeliveredPeriod} common={3} />
            
            <Grid className="full-container1">
                    <SpeedInstructionGraph2
                      vesselId={vesselId}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  </Grid>
            
          </Grid>

          {/* <div style={{ marginTop: '20px' }}> */}
          <div className="pdf-footer4 " style={{ fontSize: "12px", textAlign: "right", marginRight: "20px" }}>
  Page 3 of 4
</div> 
          {/* </div> */}
          
        </div>
        <div className="pdf-page" style={{ pageBreakAfter: 'always' }} >
        <Grid className="widthfull" id="divToPrint" style={{ padding: '10px 20px 0px 20px' }}>
        <Grid style={{ backgroundColor: '#EBEBEB', padding: '5px' }} container>
              <Grid flexGrow={1}>
              <Typography className="vesselreport" align="left">
                  <span style={{fontSize:"12px"}}>VESSEL EMISSION REPORT</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}> Vessel: {vesselInfo.vesselName} | {vesselInfo.vesselType}</span>
                </Typography>
                <Typography className="vesselreport" align="left">
                <span style={{fontSize:"12px"}}>Period : {moment(fromDate).format("DD-MMM-YYYY HH:mm")} -{" "}
                  {moment(toDate).format("DD-MMM-YYYY HH:mm")} <span style={{marginLeft:"10px" }} > | {" "}
                  {calculateDays()
                    ? roundToDecimaldays(calculateDays(), 1)
                    : ""}{" "}
                    days</span></span>
                </Typography>
              </Grid>
              <Grid display="flex" alignItems="center">
                <img alt="logo" src={Logo} width="130px" style={{ paddingRight: '10px' }} />
              </Grid>
            </Grid>

            <Divider sx={{ borderBottomWidth: '2px', marginBottom: '10px 10px 10px 10px' }} color="grey" />
            <div
  className="abbservation"
  style={{ textAlign: 'left', padding: '20px 30px', marginTop: '30px' }}
>
  <span style={{ fontSize: "18px", fontWeight: "700" }}>Abbreviations :</span>
  <div><br /></div>
  <div>
    {abbreviations.map((item, index) => (
      <div key={index} style={{ marginBottom: '5px', fontSize: "13px" }}>
        <strong>{item.acronym}:</strong> {item.definition}
      </div>
    ))}
  </div>
  <div><br /></div>
  <span style={{ fontSize: "18px", fontWeight: "700" }}>Notes :</span>
  <div>
    {notes.map((note, index) => (
      <div key={index} style={{ marginBottom: '5px', fontSize: "13px" }}>{note}</div>
    ))}
  </div>
</div>
    </Grid>
    {/* <Grid className="pdf-footer3 " style={{ fontSize: "12px", textAlign: "right", marginRight: "20px" }}>
  Page 4 of 4
</Grid>  */}
<div className="pdf-footer3" style={{ fontSize: "12px", textAlign: "right", marginRight: "20px" }}>
  Page 4 of 4
</div> 

    </div>
    
    

  
      </div>
    {/* </div> */}
     </>
     
   
  );
}
